
import tabnulogo from "../../Assets/Images/ui/tabnuTempLogo.png"
import zsahlogo from "../../Assets/Images/ui/zsahLogo.png"
import rightBanner from '../../Assets/Images/logos.jpg'

import React, { useState, Component } from "react";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import "./loginStyle.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../Assets/fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import { Auth } from 'aws-amplify';
//import * as CryptoJS from 'crypto-js';
import Axios from 'axios'
//import * as serviceWorker from '../../serviceWorker';

//const apiUrlPrefix =serviceWorker.applicationConfig().apiUrlPrefix;
const apiUrlPrefix = process.env.REACT_APP_APIURLPREFIX;//serviceWorker.applicationConfig().apiUrlPrefix;

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: '',
      password: '',
      validateId: 0,
      validateMessage: '',
      currentUserId:''
    };
  }

  validateForm() {
    if (this.state.validateId > 0) {
      this.setState({ validateId: 0 });
      this.setState({ validateMessage: '' });
    }

    else if (this.state.email.length === 0) {
      this.setState({ validateId: 1 });
      this.setState({ validateMessage: "email is required" });
      return false;
    }
    else if (!this.ValidateEmail(this.state.email)) {
      this.setState({ validateId: 2 });
      this.setState({ validateMessage: "email pattern is not macthing" });
      return false;
    }
    else if (this.state.password.length === 0) {
      this.setState({ validateId: 3 });
      this.setState({ validateMessage: "password is required" });
      return false;
    }
    return true;
    //return this.state.email.length > 0 && this.state.password.length > 0;
  }

  ValidateEmail(mail) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
      return true;
    }
    return false;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  checkEmail = async (email) => {
    let status = false;
    const params = {
      emailId: email,
    };
    await Axios.post(apiUrlPrefix + "/getUser", params)
      .then((res) => {
        if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
          const currentUser = res.data.body[0];
          if (currentUser.role.toLowerCase() != 'admin') {

            if (currentUser.status === true && currentUser.isdelete == false) {
              if (currentUser.usrcmp != null && currentUser.usrcmp.type_id > 0) {
                this.props.setUserOrganisationType(currentUser.usrcmp.type_id);
                this.setState({currentUserId:currentUser.id.toString()});
              }
              status = true;
            }
            else {
              status = false;
              this.setState({ validateId: 7 });
              this.setState({ validateMessage: "Admin approval is pending" });
            }
          }
          else {
            this.setState({ validateId: 6 });
            this.setState({ validateMessage: "This email id is not Authorised." });
            status = false;
          }
        }
        else {
          this.setState({ validateId: 5 });
          this.setState({ validateMessage: "This email id is not registered" });
          status = false;
        }
      })
      .catch(err => {
        console.log(err);
        status = false;
      });
    return status;
  }

  handleSubmit = async event => {
    event.preventDefault();

    if (this.state.isLoading) { return; }
    this.setState({email:this.state.email.toLowerCase()});
    const validationStatus = this.validateForm();
    if (!validationStatus) {
      return false;
    }

    this.setState({ isLoading: true });

    // AWS Cognito integration here
    try {
      this.setState({currentUserId:''});
      const currentUser = await this.checkEmail(this.state.email.toLowerCase());
      if (currentUser) {
        const user = await Auth.signIn(this.state.email.toLowerCase(), this.state.password);
        console.log(user);
        if (user !== null) {
          if (user.attributes['custom:user_id']!==null && Number(user.attributes['custom:user_id']) > 0) {
            
          }
          else {
            await Auth.updateUserAttributes(user, {
              'custom:user_id': this.state.currentUserId,
            });
          }
          this.props.setUser(user);
            this.setState({ isLoading: false });
            this.props.history.push("/dashboard");
        }
      }
      else {
        this.setState({ isLoading: false });
      }

    } catch (error) {
      this.setState({ isLoading: false });
      if (error.code == 'NotAuthorizedException' || error.code == 'UserNotConfirmedException') {
        this.setState({ validateId: 4 });
        this.setState({ validateMessage: error.message });
      }

      let err = null;
      !error.message ? err = { "message": error } : err = error;
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err
        }
      });
    }
  };

  render() {
    return (
      <div className="mai-wrapper mai-login">
        <div className="main-content container-fluid">
          <div className="splash-container row">
            <div className="col-md-6 login-form-main pb-5">
              <div className="row">
                <div className=" col-md-7 m-auto form-message">
                  <div>
                    <div className="logo-top-space text center logo-center my-5" >
                      <img src={tabnulogo} alt="zsah-white" className=" tabnu-logo" />
                    </div>
                    <div className="logo-text text center logo-center " >
                      <h1 className="logo-title">Welcome</h1>
                      <em className="text-center logo-tag-line">To your Tableau knowledge base</em>
                    </div>
                    <form onSubmit={this.handleSubmit} className="my-5 mx-3">
                      <FormGroup controlId="email" >
                        <div className="login-input-group">
                          <span className="input-icon-style">
                            <FontAwesomeIcon icon="user" />
                          </span>
                          <FormControl
                            autoFocus
                            type="email"
                            placeholder="Your Email"
                            value={this.state.email} onChange={this.handleChange}
                            className="login-input-field"
                          />
                        </div>
                        {this.state.validateId === 1 && <span className="registration-validation">{this.state.validateMessage}</span>}
                        {this.state.validateId === 2 && <span className="registration-validation">{this.state.validateMessage}</span>}
                      </FormGroup>
                      <FormGroup controlId="password" >
                        <div className="login-input-group">
                          <span className="input-icon-style">
                            <FontAwesomeIcon icon="lock" />
                          </span>
                          <FormControl
                            autoFocus
                            placeholder="Password"
                            type="password"
                            value={this.state.password} onChange={this.handleChange}
                            className="login-input-field"
                          />
                        </div>
                        {this.state.validateId == 3 && <span className="registration-validation">{this.state.validateMessage}</span>}
                      </FormGroup>

                      <div className="form-group row login-tools">

                        <div className="col-sm-12 pt-3 text-sm-right login-forgot-password">

                          <LinkContainer to="/resetpassword" className='forgot-password-style'>
                            <span>Forgot Password?</span>
                          </LinkContainer>
                        </div>
                      </div>
                      <div className="form-group login-submit login-submit1">
                        <Button block className="btn login-btns"
                          type="submit">
                          {!this.state.isLoading ? 'Login' : 'Please wait...'}
                        </Button>
                      </div>
                      <div style={{ marginTop: '15px' }}>
                        {this.state.validateId >= 4 && <span className="registration-validation">{this.state.validateMessage}</span>}
                      </div>
                      <div className="form-group login-submit m-auto text-center">
                        <p>Don't you have an account yet?
                          <LinkContainer to="/registration" className='forgot-password-style'>
                            <span className=""> Register</span>
                          </LinkContainer>
                        </p>
                      </div>
                    </form>
                    <div className="mt-5 out-links login-footer-logo-center "><span>Powered by</span><br />
                      <img src={zsahlogo} alt="zsah-white" className="login-footer-image" />
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="col-md-6 py-4 m-auto text-center">
              <img src={rightBanner} alt="Training Portal" className="logos-img pt-3" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
