
import React, { Component } from 'react';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Checkbox } from '@material-ui/core';
import { LinkContainer } from 'react-router-bootstrap';
import zsahlogo from "../../Assets/Images/partners/partnerTableau.png"
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../Assets/fontawesome';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState,
} from 'react-accessible-accordion';
import ProgressBar from '../../Directives/ProgressBar/progressBar'
//import * as serviceWorker from '../../serviceWorker';
import './dashboardStyle.css'
import ScaleLoader from "react-spinners/ScaleLoader";

const apiUrlPrefix =  process.env.REACT_APP_APIURLPREFIX;//serviceWorker.applicationConfig().apiUrlPrefix;
const documentPath =  process.env.REACT_APP_DOCUMENTURL;//serviceWorker.applicationConfig().documentUrl;


export default class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            categories: [],
            types: [],
            groups: [],
            subGroups: [],
            isExpand: false,
            selectedGroup: 0 ,
            userOrganisationTypeId: parseInt(props.userOrganisationTypeId)
        };
    }

    componentDidMount = async () => {
        try {
            //console.log(process.env);
            await this.loadTypes();
            await this.loadCategories();
            await this.loadGroups();
            if (this.props.selectedGroupId != null && parseInt(this.props.selectedGroupId) > 0) {
                this.expandGroup(parseInt(this.props.selectedGroupId));
            }
        }
        catch (error) {
            if (error !== 'No current user') {
                console.log(error);
            }
        }
    };

    addDefaultSrc = (event) => {
        event.target.src = zsahlogo;
    }

    secondsToHms = (d) => {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + (h === 1 ? " hr, " : " hrs, ") : "";
        var mDisplay = m > 0 ? m + (m === 1 ? " min, " : " mins, ") : "";
        var sDisplay = s > 0 ? s + (s === 1 ? " sec" : " sec") : "";
        return hDisplay + mDisplay + sDisplay;
    }

    loadTypes = async () => {
        this.setState({ isLoading: true });
        await Axios.post(apiUrlPrefix + "/getTypesClient", null, this.props.apiHeaderRequest)
            .then((res) => {
                console.log('types');
                if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
                    let typeList = res.data.body;
                    typeList.map(obj => {
                        obj.checked = true;
                        return obj;
                    });
                    this.setState({ types: typeList });
                }
                this.setState({ isLoading: false });
            })
            .catch(err => {
                console.log(err);
                this.setState({ isLoading: false });
            });
    }

    loadCategories = async () => {
        this.setState({ isLoading: true });
        await Axios.post(apiUrlPrefix + "/getCategoriesClient", null, this.props.apiHeaderRequest)
            .then((res) => {
                console.log('category');
                if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {

                    let categoryList = res.data.body;
                    categoryList.map(obj => {
                        obj.checked = true;
                        //this.loadGroups(obj.id);
                    });
                    
                    this.setState({ categories: categoryList });
                    
                }
                this.setState({ isLoading: false });
            })
            .catch(err => {
                console.log(err);
                this.setState({ isLoading: false });
            });
    };

    loadGroups = async (objCategoryId) => {
        var catId='';
        var typeId ='';
        catId=this.state.categories.filter(x=>x.checked).map(x=>{ return x.id}).join(",");
        typeId=this.state.types.filter(x=>x.checked).map(x=>{ return x.id}).join(",");
        console.log('catId: '+catId);
        console.log('typeId: '+typeId);

        this.setState({ isLoading: true });
        const params = {
            categoryId: catId===''?'0':catId,
            typeId:typeId===''?'0':typeId,
            userId: this.props.userId,
            languageId: this.props.userLanguageId
        };
        await Axios.post(apiUrlPrefix + "/getGroupsClient?", params, this.props.apiHeaderRequest)
            .then((res) => {
                if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
                    console.log('group');
                    this.setState({ groups: [] });
                    let lstGroups = this.state.groups;
                    res.data.body.map(obj => {
                        obj.displayTime = this.secondsToHms(obj.vlen);
                        lstGroups.push(obj);
                    });
                    this.setState({ groups: lstGroups });
                }
                 else if (res != null && res.data != null && res.data.statusCode === 400) {
                    this.setState({ groups: [] });
                }
                this.setState({ isLoading: false });
            })
            .catch(err => {
                console.log(err);
                this.setState({ isLoading: false });
            });
    };

    expandGroup = async (groupId) => {

        if (groupId===this.state.selectedGroup && !this.state.isExpand ){
            this.setState({isExpand:true});
        }
        else if(groupId!==this.state.selectedGroup ){
            this.setState({isExpand:false});
            this.setState({ selectedGroup: groupId });
            this.setState({ isLoading: true });
            const params = {
                groupId: groupId,
                userId: this.props.userId,
                languageId: this.props.userLanguageId
            };
            await Axios.post(apiUrlPrefix + "/getContentsClient", params, this.props.apiHeaderRequest)
                .then((res) => {
                    if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
                        var hideCompleted = document.getElementsByName("hideCompleted")[0];
                        this.setState({ subGroups: [] });
                        let lstSubGroups = this.state.subGroups;
                        res.data.body.map(obj => {
                            if (lstSubGroups.find(x => x.videoid === obj.videoid) == null) {
                                obj.displayTime = this.secondsToHms(obj.videolength);
                                obj.show = hideCompleted.checked && obj.videolength === obj.progress_video_length ? false : true;
                                obj.CompletedPercentage = (parseFloat(obj.progress_video_length / obj.videolength) * 100).toFixed(2);
                                lstSubGroups.push(obj);
                            }
                            return obj;
                        });
                        this.setState({ subGroups: lstSubGroups });
                    }
                    this.setState({ isLoading: false });
                    this.setState({ isExpand: true });
                })
                .catch(err => {
                    console.log(err);
                    this.setState({ isLoading: false });
                });
        }
        else{
            if(this.state.isExpand){
                this.setState({isExpand:false});
            }
            
        }
    };

    handleCategoriesCheckBoxChange = (obj, event) => {
        const target = event.target;
        var value = obj;
        this.state.categories.map(obj => (obj.id === value ? (obj.checked = target.checked) : ""));
        // setCategories(categories);
        this.loadGroups();
        // if (target.checked) {
        //     this.loadGroups();
        // } else {
        //     this.removeGroup(value);
        // }
    }
    
    handleTypesCheckBoxChange = (obj, event) => {
        const target = event.target;
        var value = obj;
        this.state.types.map(obj => (obj.id === value ? (obj.checked = target.checked) : ""));
        // setCategories(categories);
        this.loadGroups();
        // if (target.checked) {
        //     this.loadGroups();
        // } else {
        //     this.removeGroup(value);
        // }
    }

    handleHideCheckBoxChange = (event) => {
        const target = event.target;
        let filterSubGroups = [];
        this.state.subGroups.map(s => {
            if (s.progress_video_length === s.videolength) {
                if (target.checked) {
                    s.show = false;
                } else {
                    s.show = true;
                }
            }

            filterSubGroups.push(s);
        });

        //let filterSubGroups = this.state.subGroups.filter(x=>x.progress_video_length!==x.videolength);
        this.setState({ subGroups: filterSubGroups });
    }

    removeGroup = (objCategoryId) => {
        let groupsList = this.state.groups.filter(x => x.cid !== objCategoryId);
        let subGroupsList = this.state.subGroups.filter(x => x.categoryId !== objCategoryId);
        this.setState({ groups: groupsList });
        this.setState({ subGroups: subGroupsList });
    }

    render() {
        return (
            <React.Fragment>
                <div className={this.state.isLoading ? 'loader-style' : ''} >
                    <ScaleLoader
                        size={150}
                        color={"white"}
                        loading={this.state.isLoading}
                    />
                </div>
                <div className="row">
                    <div className="tab-container col-md-10 m-auto mb-5" id="courses">
                        <div className="row align-items-end no-gutters">
                            <div className="col-md-3">

                            {
                                this.state.types && this.state.types.map((val, ind) =>
                                (
                                    <span className={`badge badge-pill badge-big mr-3 ${val.name=="Eqolines"?"badge-eqolines":val.name=="Training"?"badge-training":""}`} key={ind}> 
                                        <FormControlLabel
                                            value="value"
                                            className="checkbox-light"
                                            control={<Checkbox color="primary" />}
                                            value={val.name}
                                            onChange={this.handleTypesCheckBoxChange.bind(this, val.id)}
                                            name={val.name}
                                            checked={val.checked}
                                        />  {val.name}
                                    </span>
                                ))
                            }
                            </div>
                            <div className="col-md-5">
                            {
                                this.state.categories && this.state.categories.map((val, ind) =>
                                (
                                    <span className="badge badge-pill badge-light badge-cat mr-2" key={ind}>
                                        <FormControlLabel
                                            value="value"
                                            control={<Checkbox color="primary" />}
                                            value={val.name}
                                            onChange={this.handleCategoriesCheckBoxChange.bind(this, val.id)}
                                            name={val.name}
                                            checked={val.checked}
                                        />  {val.name}
                                    </span>
                                ))
                            }
                            <span className="badge badge-pill badge-light badge-cat">
                                <FormControlLabel
                                    value="value"
                                    control={<Checkbox color="primary" />}
                                    onChange={this.handleHideCheckBoxChange}
                                    name="hideCompleted"
                                    id="hideCompleted"
                                />  Hide completed
                            </span>
                        </div>
                        </div>
                        <div className="mt-4">
                            {
                                this.state.categories && this.state.categories.filter(x => x.checked === true).map((val, ind) =>
                                (
                                    <div key={ind}>
                                        {
                                            <React.Fragment>
                                            <h5 className="course-title mt-4"><FontAwesomeIcon icon="tag" className="tag-gray mr-2" /> {val.name}</h5>
                                            <hr className="dashboard-group-seperator" />
                                            <Accordion allowZeroExpanded key={ind} className="row">
                                                {
                                                    this.state.groups && this.state.groups.filter((q, idx) => q.cid === val.id).map((v, i) =>
                                                    (
                                                        <AccordionItem key={i} className="col-md-4 px-2 my-3" dangerouslySetExpanded={this.state.selectedGroup===v.gid && this.state.isExpand}>
                                                            <AccordionItemHeading >
                                                                <AccordionItemButton  >
                                                                    <div  className={`group-container ${this.state.userOrganisationTypeId!==3 && v.type_id!==this.state.userOrganisationTypeId?"grey-out-container":""}`} 
                                                                     onClick={() => { this.expandGroup(v.gid) }} >
                                                                        <div className="text-center">
                                                                            <img src={documentPath + v.imagepath + '?v=' + Math.random()} onError={this.addDefaultSrc} alt="tabnu" className="group-img" />
                                                                        </div>
                                                                        <div className="py-2 px-4" >
                                                                            <div className="row no-gutters mt-3">
                                                                                <div className="col-11">
                                                                                    <h4 className="course-title">{v.gname}</h4>
                                                                                </div>
                                                                                <div className="col-1 arrow-icon">
                                                                                    <AccordionItemState >
                                                                                        {({ expanded }) =>  expanded ?
                                                                                            (
                                                                                                <FontAwesomeIcon icon="chevron-up" />
                                                                                            ) : (
                                                                                                <FontAwesomeIcon icon="chevron-down" />
                                                                                            )
                                                                                        }
                                                                                    </AccordionItemState>
                                                                                </div>
                                                                                <div><span className={`badge badge-pill ${v.typename==="Eqolines"?"badge-eqolines":v.typename==="Training"?"badge-training":""}`}>{v.typename}</span> <span className="badge badge-pill badge-light">{v.categoryname}</span></div>
                                                                                <div className="col-sm-12 my-2 dashboard-sub-group-right-panel">
                                                                                    <span className="float-left blue-color">
                                                                                        <FontAwesomeIcon icon="stopwatch" /> Approx time: {v.displayTime}
                                                                                    </span>
                                                                                    <div className="text-right blue-color">
                                                                                        {
                                                                                            (parseInt(v.usrcnt) === parseInt(v.vidcnt)) ? (
                                                                                                <span className="download-link"><FontAwesomeIcon icon="check-circle" color="White" /> Completed </span>
                                                                                            ) : (
                                                                                                <React.Fragment>
                                                                                                    <span className="group-right-detail float-right blue-color">{v.usrcnt > 0 ? v.usrcnt : 0}/{v.vidcnt}</span><br />
                                                                                                    <ProgressBar bgcolor="#0096FF" hideClass={true} displayInfo={false} completed={v.usrcnt === 0 ? 0 : (v.usrcnt / v.vidcnt) * 100} />
                                                                                                </React.Fragment>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="py-3">
                                                                                <h5>{v.subgroup}</h5>
                                                                                <p className="sub-description mb-0">
                                                                                    {v.description===null?'': v.description.length <= 100 ? v.description : v.description.substring(0, 100) + '...'}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel className={`col-12 p-0 sub-group-container`} >
                                                                {
                                                                    this.state.subGroups && this.state.subGroups.filter((q, idx) =>
                                                                        q.category === val.id && q.show ).map((sVal, sInd) => (
                                                                            <div key={sInd}  className={`col-md-11 mx-auto my-3 pt-2 video-content-container`} 
                                                                             >
                                                                                <a href={this.state.userOrganisationTypeId!==3 && v.type_id!==this.state.userOrganisationTypeId?`#`:`/content/${sVal.videoid}/${sVal.group}`}>
                                                   
                                                                                    <div>
                                                                                    
                                                                                        <a href={this.state.userOrganisationTypeId!==3 && v.type_id!==this.state.userOrganisationTypeId?`#`:`/content/${sVal.videoid}/${sVal.group}`}>
                                                                                            <h5>{sVal.videoname}</h5>
                                                                                        </a>
                                                                                        <div className='dashboard-sub-group-right-panel pb-3'>
                                                                                            <span> <FontAwesomeIcon icon="stopwatch" /> Approx time: {sVal.displayTime} </span>
                                                                                            {sVal.progress_video_length === sVal.videolength ? <ProgressBar bgcolor="#76B305" hideClass={false} displayInfo={true} completed={sVal.CompletedPercentage = 100} /> :
                                                                                                (
                                                                                                    (sVal.content_type === 'Video' || sVal.content_type === 'video') && <ProgressBar bgcolor="#0096FF" hideClass={false} displayInfo={true} completed={sVal.CompletedPercentage > 0 ? sVal.CompletedPercentage : 0} />
                                                                                                )
                                                                                            }
                                                                                            {
                                                                                                sVal.attachmentkey &&
                                                                                                <a href={documentPath + sVal.attachmentkey + '?v=' + Math.random()}>
                                                                                                    <span className="download-link"><FontAwesomeIcon icon="download" color="White" /> Download</span>
                                                                                                </a>

                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        ))
                                                                }
                                                            </AccordionItemPanel>
                                                        </AccordionItem>
                                                    ))
                                                }
                                            </Accordion>
                                            </React.Fragment>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}