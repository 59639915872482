import React, { Component } from 'react';
import {  FormControl, NavDropdown  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../Assets/fontawesome';
import tabnulogo from "../../Assets/Images/ui/tabnuTempLogo.png"
import footerLogo1 from "../../Assets/Images/ui/watermarkLogos.png"
import footerLogo2 from "../../Assets/Images/ui/watermarkLogos.png"
import '../../App.css';
import '../../Assets/Css/pe-icon-7-stroke.css';
import '../../Assets/Css/style.css';
import '../../Assets/Css/custom.css';
import '../../index.css';

class Terms extends Component{

    render(){
return (
	 <React.Fragment>
		 <nav className="navbar navbar-expand navbar-dark mai-top-header">
						<div className="container">
						<div className="logo-text inner-screen text center col-md-12">
							<a href="/">
							<p className="text-center">
							<img src={tabnulogo} alt="zsah-white" className="layout-logo" />
							</p>
							</a>
						</div>
						
						</div>
					</nav>
					
                    <div className="container top-space">
                    
                    <div className="row">
                    <div className="col-md-12 text-center">
                    <h2> WEBSITE TERMS OF USE</h2>
                    </div>
					<div className="col-md-12 ">
					<p>

<b>BACKGROUND</b><br/>
These Terms of Use, together with any and all other documents referred to
herein, set out the terms of use under which you may use this website,
ZSAH.NET (“Our Site”). Please read these Terms of Use carefully and ensure
that you understand them.<br/>
Your agreement to comply with and be bound by these Terms of Use is
deemed to occur upon your first use of Our Site. If you do not agree to
comply with and be bound by these Terms of Use, you must stop using Our
Site immediately. These Terms of Use do not apply to the sales of services,
hardware or software.<br/>
<b>1. Definitions and Interpretation</b><br/>
1.1 In these Terms of Use, unless the context otherwise requires, the
following expressions have the following meanings:<br/>
▪ “Account” means an account required for a User to access and/or use
certain areas of Our Site, as detailed in Clause 4;<br/>
▪ “Content” means any and all text, images, audio, video, scripts, code,
software, databases and any other form of information capable of being
stored on a computer that appears on, or forms part of, Our Site;<br/>
▪ “Paid Content” means digital content made available for sale via Our
Site;“User”means a user of Our Site;<br/>
▪ “User Content” means any content submitted to Our Site by Users
including, but not limited to, reviews and comments; and<br/>
▪ “We/Us/Our” means ZSAH Limited, a company registered in England
under 04403127, whose registered address is 73 Park Lane, Croydon,
Surrey, England, CR0 1JG.<br/>
<b>2. Information About Us</b><br/>
2.1 Our Site, ZSAH.NET, is owned and operated by ZSAH Limited, a limited
company registered in England under 04403127, whose registered address is
3A IMPERIAL ROAD, FULHAM, LONDON, SW6 2AG.<br/>
<b>3. Access to Our Site</b><br/>
3.1 Access to Our Site is free of charge.<br/>
3.2 It is your responsibility to make any and all arrangements necessary in
order to access Our Site.<br/>
3.3 Access to Our Site is provided “as is” and on an “as available” basis. We
may alter, suspend or discontinue Our Site (or any part of it) at any time and
without notice. We will not be liable to you in any way if Our Site (or any part
of it) is unavailable at any time and for any period.<br/>
<b>4. Accounts</b><br/>
4.1 Certain parts of Our Site (including the ability to purchase Paid Content
from Us) may require an Account in order to access them.<br/>
4.2 You may not create an Account if you are under 18 years of age. If you
are under 18 years of age and wish to use the parts of Our Site that require
an Account, your parent or guardian should create the Account for you and
you must only use the Account with their supervision.<br/>
4.3 When creating an Account, the information you provide must be accurate
and complete. If any of your information changes at a later date, it is your
responsibility to ensure that your Account is kept up-to-date.<br/>
4.4 We recommend that you choose a strong password for your Account,
consisting of a combination of lowercase and uppercase letters, numbers,
and symbols. It is your responsibility to keep your password safe. You must
not share your Account with anyone else. If you believe your Account is being
used without your permission, please contact Us immediately at
INFO@ZSAH.NET. We will not be liable for any unauthorised use of your
Account.<br/>
4.5 You must not use anyone else’s Account.<br/>
4.6 Any personal information provided in your Account will be collected,
used, and held in accordance with your rights and Our obligations under the
Data Protection Act 1998, as set out in Clause 17.<br/>
4.7 If you wish to close your Account, you may do so at any time. Closing your
Account will result in the removal of your information. Closing your Account
will also remove access to any areas of Our Site requiring an Account for
access.<br/>
4.8 If you close your Account, any User Content, e.g. reviews, comments etc.
you have created on Our Site will be deleted.<br/>
<b>5. Intellectual Property Rights</b><br/>
5.1 With the exception of User Content (see Clause 6), all Content included
on Our Site and the copyright and other intellectual property rights subsisting
in that Content, unless specifically labelled otherwise, belongs to or has been
licensed by Us. All Content (including User Content) is protected by
applicable United Kingdom and international intellectual property laws and
treaties.<br/>
5.2 Subject to sub-Clauses 5.3 and 5.6 you may not reproduce, copy,
distribute, sell, rent, sub-licence, store, or in any other manner re-use 
Content from Our Site unless given express written permission to do so by
Us.<br/>
5.3 You may:<br/>
▪ 5.3.1 Access, view and use Our Site in a web browser (including any web
browsing capability built into other types of software or app);<br/>
▪ 5.3.2 Download Our Site (or any part of it) for caching;<br/>
▪ 5.3.3 Print page(s) from Our Site;<br/>
▪ 5.3.4 Download extracts from pages on Our Site; and<br/>
▪ 5.3.5 Save pages from Our Site for later and/or offline viewing.<br/>
5.4 Our status as the owner and author of the Content on Our Site (or that of
identified licensors, as appropriate) must always be acknowledged.<br/>
5.5 You may not use any Content printed, saved or downloaded from Our Site
for commercial purposes without first obtaining a licence from Us (or our
licensors, as appropriate) to do so. This does not prohibit the normal access,
viewing and use of Our Site for general information purposes whether by
business users or consumers.<br/>
5.6 Nothing in these Terms of Use limits or excludes the provisions of Chapter
III of the Copyrights, Designs and Patents Act 1988 ‘Acts Permitted in Relation
to Copyright Works’, covering in particular the making of temporary copies;
the making of personal copies for private use; research and private study; the
making of copies for text and data analysis for non-commercial research;
criticism, review, quotation and news reporting; caricature, parody or
pastiche; and the incidental inclusion of copyright material.<br/>
<b>6. User Content</b><br/>
6.1 User Content on Our Site includes (but is not necessarily limited to)
product reviews, comments etc.<br/>
6.2 An Account is required if you wish to submit User Content. Please refer
to Clause 4 for more information.<br/>
6.3 You agree that you will be solely responsible for your User
Content. Specifically, you agree, represent and warrant that you have the
right to submit the User Content and that all such User Content will comply
with Our Acceptable Usage Policy, detailed below in Clause 12.<br/>
6.4 You agree that you will be liable to Us and will, to the fullest extent
permissible by law, indemnify Us for any breach of the warranties given by
you under sub-Clause 6.3. You will be responsible for any loss or damage
suffered by Us as a result of such breach.<br/>
6.5 You (or your licensors, as appropriate) retain ownership of your User
Content and all intellectual property rights subsisting therein. When you
submit User Content you grant Us an unconditional, non-exclusive, fully
transferable, royalty-free, perpetual, irrevocable, worldwide licence to use,
store, archive, syndicate, publish, transmit, adapt, edit, reproduce, distribute,
prepare derivative works from, display, perform and sub-licence your User
Content for the purposes of operating and promoting Our Site. In addition,
you also grant Other Users the right to copy and quote your User Content
within Our Site.<br/>
6.6 If you wish to remove User Content from Our Site, the User Content in
question will be deleted. Please note, however, that caching or references to
your User Content may not be made immediately unavailable (or may not be
made unavailable at all where they are outside of Our reasonable control).<br/>
6.7 We may reject, reclassify, or remove any User Content from Our Site
where, in Our sole opinion, it violates Our Acceptable Usage Policy, or if We
receive a complaint from a third party and determine that the User Content
in question should be removed as a result.<br/>
<b>7. Links to Our Site</b><br/>
7.1 You are encouraged to link to Our Site provided that:<br/>
▪ 7.1.1 You do so in a fair and legal manner;<br/>
▪ 7.1.2 You do not do so in a manner that suggests any form of
association, endorsement or approval on Our part where none exists;<br/>
▪ 7.1.3 You do not use any logos or trade marks displayed on Our Site
without Our express written permission; and<br/>
▪ 7.1.4 You do not do so in a way that is calculated to damage Our
reputation or to take unfair advantage of it.<br/>
7.2 You may link to any page of Our Site.<br/>
7.3 Framing or embedding of Our Site on other websites is not permitted
without Our express written permission. Please contact Us at
INFO@ZSAH.NET for further information.<br/>
7.4 You may not link to Our Site from any other site the main content of which
contains material that:<br/>
▪ 7.4.1 is sexually explicit;<br/>
▪ 7.4.2 is obscene, deliberately offensive, hateful or otherwise
inflammatory;<br/>
▪ 7.4.3 promotes violence;<br/>
▪ 7.4.4 promotes or assists in any form of unlawful activity;<br/>
▪ 7.4.5 discriminates against, or is in any way defamatory of, any person,
group or class of persons, race, gender, religion, nationality, disability,
sexual orientation, or age;<br/>
▪ 7.4.6 is intended or is otherwise likely to threaten, harass, annoy, alarm,
inconvenience, upset, or embarrass another person;<br/>
▪ 7.4.7 is calculated or is otherwise likely to deceive another person;<br/>
▪ 7.4.8 is intended or is otherwise likely to infringe (or to threaten to
infringe) another person’s privacy;<br/>
▪ 7.4.9 misleadingly impersonates any person or otherwise
misrepresents the identity or affiliation of a particular person in a way
that is calculated to deceive (obvious parodies are not included in this
definition provided that they do not fall within any of the other
provisions of this sub-Clause 7.4);<br/>
▪ 7.4.10 implies any form of affiliation with Us where none exists;<br/>
▪ 7.4.11 infringes, or assists in the infringement of, the intellectual
property rights (including, but not limited to, copyright, trade marks and
database rights) of any other party; or<br/>
▪ 7.4.12 is made in breach of any legal duty owed to a third party
including, but not limited to, contractual duties and duties of
confidence;<br/>
▪ 7.4.13 is calculated to damage our search page ranking by linking spam
websites or PBN’s (Private Blogging Networks) to any pages on Our Site.<br/>
7.5 The content restrictions in sub-Clause 7.4 do not apply to content
submitted to sites by other users provided that the primary purpose of the
site accords with the provisions of sub-Clause 7.4. You are not, for example,
prohibited from posting links on general-purpose social networking sites
merely because another user may post such content. You are, however,
prohibited from posting links on websites which focus on or encourage the
submission of such content from users.<br/>
<b>8. Links to Other Sites</b><br/>
Links to other sites may be included on Our Site. Unless expressly stated,
these sites are not under Our control. We neither assume nor accept
responsibility or liability for the content of third party sites. The inclusion of
a link to another site on Our Site is for information only and does not imply
any endorsement of the sites themselves or of those in control of them.<br/>
<b>9. Disclaimers</b><br/>
9.1 Nothing on Our Site constitutes advice on which you should rely. It is
provided for general information purposes only. Professional or specialist
advice should always be sought before taking any action on the basis of any
information provided on Our Site.<br/>
9.2 Insofar as is permitted by law, We make no representation, warranty, or
guarantee that Our Site will meet your requirements, that it will not infringe
the rights of third parties, that it will be compatible with all software and
hardware, or that it will be secure. If, as a result of Our failure to exercise
reasonable care and skill, any digital content from Our Site damages your
device or other digital content belonging to you, you may be entitled to
certain legal remedies. For more details concerning your rights and remedies
as a consumer, please contact your local Citizens Advice Bureau or Trading
Standards Office.<br/>
9.3 We make reasonable efforts to ensure that the Content on Our Site is
complete, accurate, and up-to-date. We do not, however, make any
representations, warranties or guarantees (whether express or implied) that
the Content is complete, accurate, or up-to-date.<br/>
<b>10. Our Liability</b><br/>
10.1 The provisions of this Clause 10 apply only to the use of Our Site and
not to Paid Content, which is governed separately. Limitations and exclusions
stated to apply to Content in this Clause 10 may not apply to Paid Content.<br/>
10.2 To the fullest extent permissible by law, We accept no liability to any
User for any loss or damage, whether foreseeable or otherwise, in contract,
tort (including negligence), for breach of statutory duty, or otherwise, arising
out of or in connection with the use of (or inability to use) Our Site or the use 
of or reliance upon any Content (including User Content, but not including
Paid Content) included on Our Site.<br/>
10.3 To the fullest extent permissible by law, We exclude all representations,
warranties, and guarantees (whether express or implied) that may apply to
Our Site or any Content (excluding Paid Content) included on Our Site.<br/>
10.4 If you are a business user, We accept no liability for loss of profits, sales,
business or revenue; loss of business opportunity, goodwill or reputation;
loss of anticipated savings; business interruption; or for any indirect or
consequential loss or damage.<br/>
10.5 We exercise all reasonable skill and care to ensure that Our Site is free
from viruses and other malware. However, subject to sub-Clause 9.2, We
accept no liability for any loss or damage resulting from a virus or other
malware, a distributed denial of service attack, or other harmful material or
event that may adversely affect your hardware, software, data or other
material that occurs as a result of your use of Our Site (including the
downloading of any Content from it) or any other site referred to on Our Site.<br/>
10.6 We neither assume nor accept responsibility or liability arising out of any
disruption or non-availability of Our Site resulting from external causes
including, but not limited to, ISP equipment failure, host equipment failure,
communications network failure, natural events, acts of war, or legal
restrictions and censorship.<br/>
10.7 Nothing in these Terms of Use excludes or restricts Our liability for fraud
or fraudulent misrepresentation, for death or personal injury resulting from
negligence, or for any other forms of liability which cannot be excluded or
restricted under English law. For full details of consumers’ legal rights,
including those relating to digital content, please contact your local Citizens’
Advice Bureau or Trading Standards Office.<br/>
<b>11. Viruses, Malware and Security</b><br/>
11.1 We exercise all reasonable skill and care to ensure that Our Site is secure
and free from viruses and other malware.<br/>
11.2 You are responsible for protecting your hardware, software, data and
other material from viruses, malware, and other internet security risks.<br/>
11.3 You must not deliberately introduce viruses or other malware, or any
other material which is malicious or technologically harmful either to or via
Our Site.<br/>
11.4 You must not attempt to gain unauthorised access to any part of Our
Site, the server on which Our Site is stored, or any other server, computer, or
database connected to Our Site.<br/>
11.5 You must not attack Our Site by means of a denial of service attack, a
distributed denial of service attack, or by any other means.<br/>
11.6 By breaching the provisions of sub-Clauses 11.3 to 11.5, you may be
committing a criminal offence under the Computer Misuse Act 1990. Any
and all such breaches will be reported to the relevant law enforcement
authorities and We will cooperate fully with those authorities by disclosing
your identity to them. Your right to use Our Site will cease immediately in
the event of such a breach.<br/>
<b>12. Acceptable Usage Policy</b><br/>
12.1 You may only use Our Site in a manner that is lawful and that complies
with the provisions of this Clause 12. Specifically:<br/>
▪ 12.1.1 you must ensure that you comply fully with any and all local,
national or international laws and/or regulations;<br/>
▪ 12.1.2 you must not use Our Site in any way, or for any purpose, that is
unlawful or fraudulent;<br/>
▪ 12.1.3 you must not use Our Site to knowingly send, upload, or in any
other way transmit data that contains any form of virus or other
malware, or any other code designed to adversely affect computer
hardware, software, or data of any kind; and<br/>
▪ 12.1.4 you must not use Our Site in any way, or for any purpose, that is
intended to harm any person or persons in any way.<br/>
12.2 When submitting User Content (or communicating in any other way
using Our Site), you must not submit, communicate or otherwise do anything
that:<br/>
▪ 12.2.1 is sexually explicit;<br/>
▪ 12.2.2 is obscene, deliberately offensive, hateful or otherwise
inflammatory;
▪ 12.2.3 promotes violence;<br/>
▪ 12.2.4 promotes or assists in any form of unlawful activity;<br/>
▪ 12.2.5 discriminates against, or is in any way defamatory of, any person,
group or class of persons, race, gender, religion, nationality, disability,
sexual orientation or age;<br/>
▪ 12.2.6 is intended or otherwise likely to threaten, harass, annoy, alarm,
inconvenience, upset, or embarrass another person;<br/>
▪ 12.2.7 is calculated or is otherwise likely to deceive;<br/>
▪ 12.2.8 is intended or otherwise likely to infringe (or threaten to infringe)
another person’s right to privacy;<br/>
▪ 12.2.9 misleadingly impersonates any person or otherwise
misrepresents your identity or affiliation in a way that is calculated to
deceive (obvious parodies are not included within this definition
provided that they do not fall within any of the other provisions of this
sub-Clause 12.2);<br/>
▪ 12.2.10 implies any form of affiliation with Us where none exists;<br/>
▪ 12.2.11 infringes, or assists in the infringement of, the intellectual
property rights (including, but not limited to, copyright, patents,
trademarks and database rights) of any other party; or<br/>
▪ 12.2.12 is in breach of any legal duty owed to a third party including,
but not limited to, contractual duties and duties of confidence.<br/>
12.3 We reserve the right to suspend or terminate your access to Our Site if
you materially breach the provisions of this Clause 12 or any of the other
provisions of these Terms of Use. Specifically, We may take one or more of
the following actions:<br/>
▪ 12.3.1 suspend, whether temporarily or permanently, your Account
and/or your right to access Our Site;<br/>
▪ 12.3.2 remove any User Content submitted by you that violates this
Acceptable Usage Policy;<br/>
▪ 12.3.3 issue you with a written warning;<br/>
▪ 12.3.4 take legal proceedings against you for reimbursement of any and
all relevant costs on an indemnity basis resulting from your breach;<br/>
▪ 12.3.5 take further legal action against you as appropriate;<br/>
▪ 12.3.6 disclose such information to law enforcement authorities as
required or as We deem reasonably necessary; and/or<br/>
▪ 12.3.7 any other actions which We deem reasonably appropriate (and
lawful).<br/>
12.4 We hereby exclude any and all liability arising out of any actions
(including, but not limited to those set out above) that We may take in
response to breaches of these Terms of Use.<br/>
<b>13. Privacy and Cookies</b><br/>
Use of Our Site is also governed by our Privacy Policies. These policies are
incorporated into these Terms of Use by this reference. Click the button
below for more information on Privacy.<br/>
<b>14. Changes to these Terms of Use</b><br/>
14.1 We may alter these Terms of Use at any time. Any such changes will
become binding on you upon your first use of Our Site after the changes have
been implemented. You are therefore advised to check this page from time
to time.<br/>
14.2 In the event of any conflict between the current version of these Terms
of Use and any previous version(s), the provisions current and in effect shall
prevail unless it is expressly stated otherwise.<br/>
<b>15. Contacting Us</b><br/>
To contact Us, please email Us at INFO@ZSAH.NET or call us on 020 7060
6032.<br/>
<b>16. Communications from Us</b><br/>
16.1 If We have your contact details (if, for example, you have an Account)
We may from time to time send you important notices by email. Such notices
may relate to matters including, but not limited to, service changes, changes
to these Terms of Use, Our Terms of Sale, and changes to your Account.<br/>
16.2 We will never send you marketing emails of any kind without your
express consent. If you do give such consent, you may opt out at any
time. Any and all marketing emails sent by Us include an unsubscribe link. If
you opt out of receiving emails from us at any time, it may take up to 1
business day for Us to comply with your request. During that time, you may
continue to receive emails from Us.<br/>
16.3 For questions or complaints about communications from Us (including,
but not limited to marketing emails), please contact Us at INFO@ZSAH.NET or
call us.<br/>
<b>17. Data Protection</b><br/>
17.1 Any and all personal information that We may collect will be collected,
used and held in accordance with the provisions of the Data Protection Act
1998 and your rights and Our obligations under that Act.<br/>
17.2 We may use your personal information to:<br/>
▪ 17.2.1 Provide and administer your Account;<br/>
▪ 17.2.2 Reply to any communications you send to Us;<br/>
▪ 17.2.3 Send you important notices, as detailed in Clause 16;<br/>
17.3 We will not pass on your personal information to any third parties unless
they are used by Us in the delivery of Our services to you.<br/>
<b>18. Law and Jurisdiction</b><br/>
18.1 These Terms of Use, and the relationship between you and Us (whether
contractual or otherwise) shall be governed by, and construed in accordance
with, English law.<br/>
18.2 If you are a consumer, any disputes concerning these Terms of Use, the
relationship between you and Us, or any matters arising therefrom or
associated therewith (whether contractual or otherwise) shall be subject to
the jurisdiction of the courts of England, Wales, Scotland, or Northern Ireland,
as determined by your residency.<br/>
18.3 If you are a business, any disputes concerning these Terms of Use, the
relationship between you and Us, or any matters arising therefrom or
associated therewith (whether contractual or otherwise) shall be subject to
the exclusive jurisdiction of the courts of England and Wales.
</p>
							
                    </div>
                    </div>
                    </div>

                    <div className="footer-left-section">
					TabNu Nuturing Portal
				{/* <img src={footerLogo1} alt="zsah-white" className="dashboard-footer-logo" /> */}
				</div>
				<div className="footer-right-section">
				<img src={footerLogo2} alt="zsah-white" className="dashboard-footer-logo" />
				</div>
	 </React.Fragment>
	)
    }

}
export default Terms