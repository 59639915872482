import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Routes from './Router';
import { Auth } from 'aws-amplify';
import './App.css';
import './Assets/Css/pe-icon-7-stroke.css';
import './Assets/Css/style.css';
import './Assets/Css/custom.css';
import './index.css';
import tabnulogo from "./Assets/Images/ui/tabnuTempLogo.png"
import footerLogo from "./Assets/Images/ui/watermarkLogos.png"
import { FormControl, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Assets/fontawesome';
import Axios from 'axios'
//import * as serviceWorker from './serviceWorker';
import zsahlogo from "./Assets/Images/partners/partnerTableau.png"
import ProgressBar from './Directives/ProgressBar/progressBar'
import FeaturedVideo from './Components/FeaturedVideo/FeaturedVideo'
import ScaleLoader from "react-spinners/ScaleLoader";

const apiUrlPrefix = process.env.REACT_APP_APIURLPREFIX;// serviceWorker.applicationConfig().apiUrlPrefix;
const documentPath =  process.env.REACT_APP_DOCUMENTURL;//serviceWorker.applicationConfig().documentUrl;

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isAuthenticated: false,
			isAuthenticating: true,
			user: null,
			loginUserName: '',
			search: '',
			videosList: [],
			languageList: [],
			userDefaultLanguage: '',
			userId: 0,
			userLanguageId: 1,
			jwtToken: '',
			isLoading: false,
			userEmail: '',
			selectedGroupId: 0,
			selectedContentId: 0,
			userOrganisationTypeId: parseInt(localStorage.getItem("userOrganisationTypeId"))
		};
	}

	

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	loadLanguage = async () => {
		this.setState({ isLoading: true });
		await Axios.post(apiUrlPrefix + "/getLanguages")
			.then((res) => {
				if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
					let list = res.data.body;
					this.setState({ languageList: list });
				}
				this.setState({ isLoading: false });
			})
			.catch(err => {
				console.log(err);
				this.setState({ isLoading: false });
			});
	}

	setUser = user => {
		this.setState({ user: user });
		this.setState({ loginUserName: user.attributes['name'] });
		this.setState({ userEmail: user.attributes['email'] });
		const languageId = user.attributes['custom:language_id'];
		const language = this.state.languageList.find(x => x.id === languageId);
		this.setState({ userDefaultLanguage: language ? language.code : 'En' });
		this.setState({ userLanguageId: languageId!==null && languageId!==undefined && Number(languageId)>0?languageId:1 });
		this.setState({ userId: user.attributes['custom:user_id'] });
		this.setState({ jwtToken: user.signInUserSession.idToken.jwtToken });
		this.setState({ isAuthenticated: true });
	}

	handleKeyUp = event => {
		try {
			this.setState({ isLoading: true });
			if (this.state.search.length > 0) {
				const params = {
					searchText: this.state.search,
					userId: this.state.userId,
					languageId: this.state.userLanguageId
				};
				Axios.post(apiUrlPrefix + "/searchVideos", params, { headers: { 'X-COG-ID': this.state.jwtToken } })
					.then((res) => {
						if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
							console.log('video detail');
							let lstSubGroups = [];
							this.setState({ videosList: [] });
							res.data.body.map(obj => {
								if (lstSubGroups.find(x => x.id === obj.videoid) === null || lstSubGroups.find(x => x.id === obj.videoid) === undefined) {
									obj.isGreyOut = this.state.userOrganisationTypeId !== 3 && obj.type_id !== this.state.userOrganisationTypeId;
									obj.completedVideoPercentage = ((obj.progress_video_length / obj.videolength) * 100).toFixed(2);
									lstSubGroups.push(obj);
								}

							});
							this.setState({ videosList: lstSubGroups });
						}
						this.setState({ isLoading: false });
					})
					.catch(err => {
						console.log(err);
						this.setState({ isLoading: false });
					});
			}
		}
		catch (err) {
			console.log(err);
		}

	}

	componentDidMount = async (prevStatus) => {
		try {
			const session = await Auth.currentSession();
			const user = await Auth.currentAuthenticatedUser();
			console.log("user id:"+user.attributes['custom:user_id'] );
			await this.loadLanguage();
			this.setUser(user);
		} catch (error) {
			if (error !== 'No current user') {
				console.log(error);
			}
		}

		this.setState({ isAuthenticating: false });
	}

	updateUserLanguage = (id) => {
		this.setState({ isLoading: true });
		if (id !== this.state.userLanguageId) {
			const params = {
				//userId:parseInt(this.state.userId),
				defaultLanguage: id,
				userName: this.state.user.username
			}
			Axios.post(apiUrlPrefix + "/updateDefaultLanguage", params, { headers: { 'X-COG-ID': this.state.jwtToken } })
				.then((res) => {
					if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.id > 0) {

						const language = this.state.languageList.find(x => x.id === id);
						this.setState({ userDefaultLanguage: language ? language.code : 'En' });
						this.setState({ isLoading: false });
						alert("Language updated successfully. Please login again.");
						Auth.signOut();

						this.userHasAuthenticated(false);
						this.props.history.push('/login');
					}
				})
				.catch(err => {
					console.log(err);
					this.setState({ isLoading: false });
				});
		}

	}

	userHasAuthenticated = authenticated => {
		this.setState({ isAuthenticated: authenticated });
	};

	setSelectedGroup = groupId => {
		this.setState({ selectedGroupId: groupId });
	}

	setSelectedContent = contentId => {
		this.setState({ selectedContentId: contentId });
	}
	setUserOrganisationType = typeId => {
		this.setState({ userOrganisationTypeId: typeId });
		localStorage.setItem("userOrganisationTypeId", typeId)
	}

	addDefaultSrc = (event) => {
		event.target.src = zsahlogo;
	}

	headerLayout() {
		const childProps = {
			isAuthenticated: this.state.isAuthenticated,
			userHasAuthenticated: this.userHasAuthenticated,
			setUser: this.setUser,
			userId: this.state.userId,
			userLanguageId: this.state.userLanguageId,
			apiHeaderRequest: { headers: { 'X-COG-ID': this.state.jwtToken } },
			search: this.state.search
		};
		return (
			<React.Fragment>
				<nav className="navbar navbar-expand navbar-dark mai-top-header">
					<div className="container">
						<div className="logo-text inner-screen text-center col-md-3 header-left-logo">
							<a href="/dashboard">
								<p className="text-center">
									<img src={tabnulogo} alt="zsah-white" className="layout-logo" />
								</p>
							</a>
						</div>
						<ul className="nav navbar-nav float-lg-right mai-user-nav">
							<li className="nav-item mobile-hidden">
							</li>
							<FontAwesomeIcon icon="language" className="dashboard-language" />
							<NavDropdown title={this.state.userDefaultLanguage} id="basic-nav-dropdown" variant="info" className="mr-4">
								{
									this.state.languageList.length > 0 && this.state.languageList.map(obj => (
										<NavDropdown.Item key={obj.id} onClick={() => { this.updateUserLanguage(obj.id) }} variant="info" > {obj.name}({obj.code})</NavDropdown.Item>
									))
								}
							</NavDropdown>
							<FontAwesomeIcon icon="user" className="dashboard-language" />
							<NavDropdown title={this.state.loginUserName} id="basic-nav-dropdown" >
								<NavDropdown.Item href="/resetpassword"><FontAwesomeIcon icon="user" /> Reset Password</NavDropdown.Item>
								<NavDropdown.Item onClick={this.handleLogout}><FontAwesomeIcon icon="power-off" /> Log Out</NavDropdown.Item>
							</NavDropdown>
						</ul>
					</div>
				</nav>
				<div className="container-fluid py-3 mb-5 internal-header">
					<div className="w-75 m-auto">
						<div className="row align-items-center justify-content-around">
							<FeaturedVideo props={childProps}></FeaturedVideo>
							<div className="search col-md-5 mt-3">
								<FormControl
									autoFocus
									type="text"
									placeholder="Search"
									id='search'
									value={this.state.search}
									onKeyUp={this.handleKeyUp}
									onChange={this.handleChange}
									className="search-input"
								/>
								<span className="pe-7s-search search-bar-icon"></span>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}

	footerLayout() {
		return (
			<React.Fragment>
				<div className="container-fluid">
					<div className="footer pb-3 pt-5 row align-items-center justify-content-around">
						<div className="footer-left-section">
							<img src={tabnulogo} alt="zsah-white" className="layout-logo mb-3" />
							<p className="col-md-6 p-0">Cutting-edge Tableau lessons for core-team members and industry experts.</p>
						</div>
						<div className="footer-right-section">
							<img src={footerLogo} alt="zsah-white" className="dashboard-footer-logo" />
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}

	searchResultLayout() {
		return (
			this.state.search.length > 0 && this.state.videosList.map((sVal, sInd) => (
				<React.Fragment key={sInd}>
					<div className={this.state.isLoading ? 'loader-style' : ''} >
						<ScaleLoader
							size={150}
							color={"white"}
							loading={this.state.isLoading}
						/>
					</div>
					<div className="col-9 m-auto">
						<div className={`row my-2 py-2 video-content-container align-items-center ${sVal.isGreyOut ? 'search-video-grey-out' : ''}`} >
							<div className="col-md-2" >
								<a href={sVal.isGreyOut ? '#' : `/content/${sVal.videoid}/${sVal.group}`} key={sInd}>
									<img src={documentPath + sVal.thumbnailkey} onError={this.addDefaultSrc} alt="video thumbnail" className="video-img" />
								</a>
							</div>
							<div className="col-md-10" >
								<a href={sVal.isGreyOut ? '#' : `/content/${sVal.videoid}/${sVal.group}`} key={sInd}>
									<h5>{sVal.videoname}</h5>
								</a>
								<div className="row align-items-center">
									<div className='col-md-8'>
										<a href={sVal.isGreyOut ? '#' : `/content/${sVal.videoid}/${sVal.group}`} key={sInd}>
											<p className="sub-group-description">
												{sVal.description.length <= 150 ? sVal.description : sVal.description.substring(0, 150) + '...'}
											</p>
											<p className="text-secondary font-weigth-bold">course: <span className="course-title">{sVal.groupname}</span></p>
											<div><span className={`badge badge-pill ${sVal.typename === "Eqolines" ? "badge-eqolines" : sVal.typename === "Training" ? "badge-training" : ""}`}>{sVal.typename}</span> <span className="badge badge-pill badge-light">{sVal.categoryname}</span></div>
										</a>
									</div>
									<div className='col-md-4  dashboard-sub-group-right-panel text-right'>
										<span> <FontAwesomeIcon icon="stopwatch" /> Approx time: {sVal.videolength} min</span>
										{/* {sVal.completedVideoPercentage === 100 ? 
										<span className="completed-link"><FontAwesomeIcon icon="check-circle" /> Completed</span> :
											(
												(sVal.content_type === 'Video' || sVal.content_type === 'video') && 
												<ProgressBar bgcolor="#76B305" hideClass={false} displayInfo={true} completed={sVal.completedVideoPercentage ?? 0} />
											)} */}
										{sVal.progress_video_length === sVal.videolength ?
											<ProgressBar bgcolor="#76B305" hideClass={false} displayInfo={true} completed={sVal.completedVideoPercentage = 100} /> :
											(
												(sVal.content_type === 'Video' || sVal.content_type === 'video') && 
												<ProgressBar bgcolor="#0096FF" hideClass={false} displayInfo={true} completed={sVal.completedVideoPercentage > 0 ? sVal.completedVideoPercentage : 0} />
											)
										}
										{
											sVal.attachmentKey &&
											<a href={sVal.isGreyOut ? '#' : documentPath + sVal.attachmentKey}>
												<span className="download-link"><FontAwesomeIcon icon="download" color="White" /> Download</span>
											</a>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</React.Fragment>
			))
		)
	}

	handleLogout = async event => {
		await Auth.signOut();

		this.userHasAuthenticated(false);
		this.props.history.push('/login');
	};

	render() {
		const childProps = {
			isAuthenticated: this.state.isAuthenticated,
			userHasAuthenticated: this.userHasAuthenticated,
			setUser: this.setUser,
			userId: this.state.userId,
			userLanguageId: this.state.userLanguageId,
			apiHeaderRequest: { headers: { 'X-COG-ID': this.state.jwtToken } },
			search: this.state.search,
			userEmail: this.state.userEmail,
			selectedGroupId: this.state.selectedGroupId,
			selectedContentId: this.state.selectedContentId,
			setSelectedContent: this.setSelectedContent,
			setSelectedGroup: this.setSelectedGroup,
			setUserOrganisationType: this.setUserOrganisationType,
			userOrganisationTypeId: this.state.userOrganisationTypeId

		};
		return (
			!this.state.isAuthenticating && <React.Fragment >
				{this.state.isAuthenticated ? (
					<React.Fragment>
						{this.headerLayout()}
						<div className="dashboad-container container-fluid">
							{
								this.state.search.length > 0 ? (this.searchResultLayout()) : (<Routes childProps={childProps} />)
							}
						</div>
						{this.footerLayout()}
					</React.Fragment>
				) :
					(
						<Routes childProps={childProps} />
					)
				}
			</React.Fragment>
		);
	}
}

export default withRouter(App);
