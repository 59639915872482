import React, { useState, useEffect, useCallback, useRef, Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../Assets/fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import Axios from 'axios';
import zsahlogo from "../../Assets/Images/partners/partnerTableau.png"
import './FeaturedVideo.css';
//import * as serviceWorker from '../../serviceWorker';
import ScaleLoader from 'react-spinners/ScaleLoader';
import thumbnail from "../../Assets/Images/thumbnail.png"

import {
  Carousel,
  CarouselItem,
  CarouselControl
} from 'reactstrap';

const apiUrlPrefix = process.env.REACT_APP_APIURLPREFIX;//serviceWorker.applicationConfig().apiUrlPrefix;
const documentPath = process.env.REACT_APP_DOCUMENTURL;//serviceWorker.applicationConfig().documentUrl;

const FeaturedVideo = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [featureList, setFeatureList] = useState([]);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === featureList.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? featureList.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const addDefaultSrc = (event) => {
    event.target.src = zsahlogo;
  }


  useEffect(() => {
    loadFeatureList();
  }, []);

  const loadFeatureList = async () => {
    const params = {
      userId: props.props.userId
    }
    var res = await Axios.post(apiUrlPrefix + '/getClientFeaturedList', params, props.props.apiHeaderRequest).catch((err) => {
      console.log(err);
      //setIsLoading(false);
    });
    if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
      console.log(res.data.body);
      setFeatureList(res.data.body);
    }
  }

  const slides = featureList.map((item) => {

    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.videoid}
      >
        <div className="ribbon ribbon-top-right"><span>Featured</span></div>
        <a href="/content/16/5" className="row no-gutters align-items-center">
          <div className="col-6 pr-4">
            <h4 className="blue-color">{item.videoname}</h4>
            <p className="text-light text-left m-0">{item.description.substring(0, 100) + '...'}</p>
          </div>
          <div className="col-6">
            <img src={documentPath + item.thumbnailkey} onError={addDefaultSrc} alt="video-thumbnail" className="video-thumbnail" />
          </div>
        </a>
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      className="feature-video col-md-5"
    >
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
}

export default FeaturedVideo;