import tabnulogo from '../../Assets/Images/ui/tabnuTempLogo.png';
import zsahawards from '../../Assets/Images/zsah4awards.png';
import zsahlogo from '../../Assets/Images/ui/zsahLogo.png';
import React, { Component } from 'react';
import { Button, FormGroup, FormControl } from 'react-bootstrap';
import '../Login/loginStyle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../Assets/fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import { Auth } from 'aws-amplify';

export default class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			isCodeSent: true,
			email: props.isAuthenticated?props.userEmail.toLowerCase():'',
			code: '',
			password: '',
			confirmPassword: ''
		};
	}

	validateForm() {
		return (
			this.state.confirmPassword.length > 0 &&
			this.state.password.length > 0 &&
			this.state.confirmPassword === this.state.password
		);
	}

	validateEmail() {
		return this.state.email.length > 0;
	}
	componentDidMount = async () => {
        try {
            await Auth.forgotPassword(this.state.email)
			.then((data) => console.log(data), alert('Please check your email for code'))
			.catch((err) => console.log(err));
        }
        catch (error) {
            if (error !== 'No current user') {
                console.log(error);
            }
        }
    };

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	SendCodeOnSubmit = async (event) => {
		event.preventDefault();
		this.setState({ isCodeSent: true });
		await Auth.forgotPassword(this.state.email)
			.then((data) => console.log(data), alert('Please check your email for code'))
			.catch((err) => console.log(err));
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		if (this.state.isLoading) {
			return;
		}

		this.setState({ isLoading: true });
		try {
			await Auth.forgotPasswordSubmit(this.state.email, this.state.code, this.state.password)
				.then((data) => console.log(data), alert('Password updated successfully'), this.props.history.push('/'))
				.catch((err) => console.log(err));
		} catch (e) {
			alert(e.message);
			this.setState({ isLoading: false });
		}
	};

	render() {
		return (
			<div className="mai-wrapper">
				<div className="main-content container-fluid">
					<div className="row">
						<div className="col-md-5 m-auto">
							<div className="row">
								<div className=" col-md-9 m-auto form-message">
									<div className="login-content-container">
											<form onSubmit={this.handleSubmit} className="">
												<h3 className="text-center course-title">Please update your password below.</h3>
												<FormGroup className="mb-1" controlId="code">
													<div className="login-input-group">
														<span className="input-icon-style">
															<FontAwesomeIcon icon="user" />
														</span>
														<FormControl
															autoFocus
															type="text"
															placeholder="Enter code"
															value={this.state.code}
															onChange={this.handleChange}
															className="login-input-field"
														/>
													</div>
												</FormGroup>

												<FormGroup className="mb-1" controlId="password">
													<div className="login-input-group">
														<span className="input-icon-style">
															<FontAwesomeIcon icon="lock" />
														</span>
														<FormControl
															autoFocus
															placeholder="Password"
															type="password"
															value={this.state.password}
															onChange={this.handleChange}
															className="login-input-field"
														/>
													</div>
												</FormGroup>
												<FormGroup className="mb-1" controlId="confirmPassword">
													<div className="login-input-group">
														<span className="input-icon-style">
															<FontAwesomeIcon icon="lock" />
														</span>
														<FormControl
															autoFocus
															type="password"
															placeholder="Confirm Password"
															value={this.state.confirmPassword}
															onChange={this.handleChange}
															className="login-input-field"
														/>
													</div>
												</FormGroup>

												<div className="form-group mb-1 mt-5">
													<Button
														block
														className="btn btn-lg btn-primary apply-hand"
														disabled={!this.validateForm()}
														type="submit"
													>
														{!this.state.isLoading ? 'Change Password' : 'Loading...'}
													</Button>
												</div>
												<div className="form-group mb-1 mt-4">
													<LinkContainer to="/" className="col-md-5 apply-hand float-left">
														<span className="btn btn-lg btn-dark login-btn-full-width">
															Go Back
														</span>
													</LinkContainer>
													<span className="col-md-2" />
													<div
														onClick={this.SendCodeOnSubmit}
														className="col-md-5 btn btn-lg btn-light login-btn-full-width apply-hand float-right"
													>
														Resend Code
													</div>
												</div>
											</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
