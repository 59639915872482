import React, { useState, useEffect, useCallback } from 'react';
//import { Button, FormGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../Assets/fontawesome';
//import { LinkContainer } from 'react-router-bootstrap';
import Axios from 'axios';
import { Document, Page } from 'react-pdf';
import './videoDetail.css';
//import * as serviceWorker from '../../serviceWorker';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import ProgressBar from '../../Directives/ProgressBar/progressBar';
import ScaleLoader from 'react-spinners/ScaleLoader';

// const apiUrlPrefix = serviceWorker.applicationConfig().apiUrlPrefix;
// const documentPath = serviceWorker.applicationConfig().documentUrl;
const apiUrlPrefix =  process.env.REACT_APP_APIURLPREFIX;//serviceWorker.applicationConfig().apiUrlPrefix;
const documentPath =  process.env.REACT_APP_DOCUMENTURL;//serviceWorker.applicationConfig().documentUrl;

const ContentDetail = (props) => {
	const [ currentContent, setCurrentContent ] = useState({});
	const [ previousContent, setPreviousContent ] = useState({});
	const [ nextContent, setNextContent ] = useState({});
	const [ isLoading, setIsLoading ] = useState(false);
	const [ numPages, setNumPages ] = useState(null);
	const [ pageNumber, setPageNumber ] = useState(1);
	const [ otherVideoList, setOtherVideoList ] = useState([]);

	//const lastInterval = useRef();
	let analyticId = 0;

	useEffect(() => {
		loadVideoDetail(parseInt(props.match.params.videoId), parseInt(props.match.params.groupId));
	}, []);

	const loadVideoDetail = useCallback(async (videoId, groupId) => {
		setIsLoading(true);
		const params = {
			groupId: groupId,
			userId: props.userId,
			languageId: props.userLanguageId
		};
		var res = await Axios.post(apiUrlPrefix + '/getContentsClient', params, props.apiHeaderRequest).catch((err) => {
			console.log(err);
			setIsLoading(false);
		});
		if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
			const list = res.data.body.sort((x) => x.sequence);
			if (list != null) {
				list.map(x=>x.completedVideoPercentage=((x.progress_video_length/x.videolength)*100).toFixed(2));
				setOtherVideoList(list);
			}
			const index = list.findIndex((x) => x.videoid === videoId);
			const current = list[index];
			const previous = list[index === 0 ? list.length - 1 : index - 1];
			const next = list[index === list.length - 1 ? 0 : index + 1];

			if (current !== null) {
				if (index > 0) {
					setPreviousContent({
						id: previous.videoid,
						image: previous.thumbnailkey,
						groupId: previous.group,
						name: previous.videoname,
						isShow: previous.group === current.group,
						completedVideoPercentage:previous.completedVideoPercentage
					});
				}
				if (index !== list.length - 1) {
					setNextContent({
						id: next.videoid,
						image: next.thumbnailkey,
						groupId: next.group,
						name: next.videoname,
						isShow: next.group === current.group,
						completedVideoPercentage:next.completedVideoPercentage
					});
				}

				setCurrentContent(current);
				if (current.analyticsid > 0) {
					analyticId = current.analyticsid;
				}

				if (current.content_type === 'video' || current.content_type === 'Video') {
					setInterval(async () => {
						console.log('interval part');
						var element = document.getElementById('videoPlayer');
						{
							if (
								element &&
								((current.videolength >= Math.round(element.currentTime) && !element.paused) ||
									(current.videolength === Math.round(element.currentTime) && element.paused))
							) {
								const params = {
									analyticsId: analyticId,
									version: 0,
									userId: parseInt(props.userId),
									videoId: videoId,
									languageId: parseInt(props.userLanguageId),
									progressVideoLength: Math.round(element.currentTime)
								};
								await Axios.post(apiUrlPrefix + '/updateContentClient', params, props.apiHeaderRequest)
									.then((res) => {
										if (
											res != null &&
											res.data != null &&
											res.data.statusCode === 200 &&
											res.data.body.length > 0
										) {
											analyticId = res.data.body[0].id;
										}
									})
									.catch((err) => {
										console.log(err);
									});
							}
						}
					}, 5000);
				}
			}
		}
		setIsLoading(false);
	}, []);

	const handleVideoMounted = (element) => {
		if (element !== null) {
			element.currentTime = parseFloat(
				currentContent.progress_video_length > 0 ? currentContent.progress_video_length : 0
			);
		}
	};

	function onDocumentLoadSuccess({ numPages: nextNumPages }) {
		setNumPages(nextNumPages);
	}

	const RedirectToDashboard=groupId=>{
		props.setSelectedGroup(groupId);
		props.history.push('/dashboard');
	}

	// const saveLastVideoLength = async()=>{
	// 	const params = {
	// 		analyticsId: analyticId,
	// 		version: 0,
	// 		userId: parseInt(props.userId),
	// 		videoId: videoId,
	// 		languageId: parseInt(props.userLanguageId),
	// 		progressVideoLength: Math.round(element.currentTime)
	// 	};
	// 	await Axios.post(apiUrlPrefix + '/updateContentClient', params, props.apiHeaderRequest)
	// 		.then((res) => {
	// 			if (
	// 				res != null &&
	// 				res.data != null &&
	// 				res.data.statusCode === 200 &&
	// 				res.data.body.length > 0
	// 			) {
	// 				analyticId = res.data.body[0].id;
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 		});
	// }

	return (
		<React.Fragment>
			<div className={isLoading ? 'loader-style' : ''}>
				<ScaleLoader size={150} color={'white'} loading={isLoading} />
			</div>

			<div className="col-md-10 m-auto">
				<div>
					<div className="mb-5 breadcrumbs">
						<a href="/dashboard">
							{' '}
							<FontAwesomeIcon icon="home" size="1x" />
						</a>
						<a className="grop-name d-inline ml-3" href='#' onClick={()=>RedirectToDashboard(currentContent.group)}>
							{currentContent.groupname} / 
						</a>
						<h6 className="ml-2" style={{ display: 'initial'}}>{currentContent.videoname}</h6>
					</div>
					<div className="col-md-7 m-auto">
						<h1 className="course-title text-center">{currentContent.videoname}</h1>
						<p>{currentContent.description}</p>
					</div>
				</div>

				<div className="video-page-extra-margin row">
					<div className="col-md-8">
						{(currentContent.content_type === 'Video' || currentContent.content_type === 'video') &&
						currentContent.videokey && (
							<video
								src={documentPath + currentContent.videokey + '?v=' + Math.random()}
								controls
								controlsList="nodownload"
								ref={handleVideoMounted}
								id="videoPlayer"
								style={{ width: '100%', hieght: '300px', background: 'black' }}
							/>
						)}

						{(currentContent.content_type === 'Document' || currentContent.content_type === 'document') && (
							<React.Fragment>
								<Document
									file={{
										url: documentPath + currentContent.videokey + '?v=' + Math.random(),
										httpHeaders: {
											'Access-Control-Allow-Origin': '*'
										}
									}}
									onLoadSuccess={onDocumentLoadSuccess}
								>
									<Page pageNumber={pageNumber} size="A4" />
									<div className="page-controls">
										<button
											className="btn"
											disabled={pageNumber === 1}
											type="button"
											onClick={() => setPageNumber(pageNumber - 1)}
										>
											‹
										</button>
										<span>
											{pageNumber} of {numPages}
										</span>
										<button
											className="btn"
											disabled={pageNumber === numPages}
											type="button"
											onClick={() => setPageNumber(pageNumber + 1)}
										>
											›
										</button>
									</div>
								</Document>
							</React.Fragment>
						)}
						<div className="next-prev row mt-3 justify-content-between">
							{previousContent &&
							previousContent.isShow && (
								<a href={`/content/${nextContent.id}/${nextContent.groupId}`}>
									<div className="previous-video ml-3">
										<div
											className="flex flex-column items-center justify-center sub-video-container"
											style={{
												backgroundSize: 'contain',
												backgroundImage:
													"url('" +
													(documentPath + previousContent.image + '?v=' + Math.random()) +
													"') ",
												height: '90px',
												width: 'auto',
												backgroundRepeat: 'no-repeat'
											}}
										>
											{previousContent.completedVideoPercentage===100?(<FontAwesomeIcon icon="check-circle" color="#76B305" size="2x" />):(<FontAwesomeIcon icon="arrow-alt-circle-left" color="#76B305" size="2x" />)}
											
										</div>
										<div className="text-center">{previousContent.name}</div>
									</div>
								</a>
							)}
							{previousContent.id ==null && (<div  className="ml-3"></div>)}
							{nextContent &&
							nextContent.isShow && (
								<a
									className="next-video mr-3"
									href={`/content/${nextContent.id}/${nextContent.groupId}`}
								>
									<div>
										<div
											className="flex flex-column items-center justify-center sub-video-container"
											style={{
												backgroundSize: 'contain',
												backgroundImage:
													"url('" +
													(documentPath + nextContent.image + '?v=' + Math.random()) +
													"') "
											}}
										>
											{nextContent.completedVideoPercentage===100?(<FontAwesomeIcon icon="check-circle" color="#76B305" size="2x" />):(<FontAwesomeIcon icon="arrow-alt-circle-right" color="#76B305" size="2x" />)}
											
										</div>
										<div className="text-center">{nextContent.name}</div>
									</div>
								</a>
							)}
						</div>
						<hr className="my-5"></hr>
						<div className="row align-items-center justify-content-between">
							{currentContent.attachmentkey && (
								<React.Fragment>
									<div className="col-md-7">
										<p>Download the document to help you get started on this module</p>
										<a className="font-weight-bold" href={documentPath + currentContent.attachmentkey + '?v=' + Math.random()}>
											<div className="download-content">
												<div className="col-sm-2 download-icon-postion text-center">
													<FontAwesomeIcon icon="download" size="2x" color="white" className="mt-2" />
												</div>
												<div className="col-sm-8 download-text-space">
													<span className="download-text-heading">Download document</span>
													<p className="download-text-description">
														{' '}
														{currentContent.attachmentkey &&
															currentContent.attachmentkey.length > 0 &&
															currentContent.attachmentkey.split('/').pop()}
													</p>
												</div>
											</div>
										</a>
									</div>
									<div className="col-md-4">
										{currentContent.updatedAt && (
											<p className="mt-4">
												<strong className="course-title">Last viewed on:</strong>
												<br />
												{new Date(currentContent.updatedAt).getUTCDate() +
													'-' +
													(new Date(currentContent.updatedAt).getUTCMonth() + 1) +
													'-' +
													new Date(currentContent.updatedAt).getUTCFullYear() +
													' ' +
													(new Date(currentContent.updatedAt).getUTCHours() > 9 ? '' : '0') +
													new Date(currentContent.updatedAt).getUTCHours().toString() +
													':' +
													(new Date(currentContent.updatedAt).getUTCMinutes() > 0
														? ''
														: '0') +
													new Date(currentContent.updatedAt).getUTCMinutes().toString()}
											</p>
										)}
									</div>
								</React.Fragment>
							)}
						</div>
						<div className="row video-page-extra-margin">
							<div>
								{currentContent.videolength === currentContent.progress_video_length && (
									<span className="download-link">
										<FontAwesomeIcon icon="check-circle" color="White" /> Completed{' '}
									</span>
								)}

								<hr />
							</div>
						</div>
					</div>
					<div className="col-md-4 course-list">
						<h5 className="course-title d-inline">{currentContent.groupname}</h5> <em className="course-title">- full lessons list</em>
						{otherVideoList &&
							otherVideoList.map((sVal, sInd) => (
								<div
									key={sInd}
									className={sVal.videoid !== currentContent.videoid ?"col p-2 mb-1 video-content-container":"current-video-item col p-2 mb-1"}
									style={{ cursor: 'pointer' }}
								>
									<a
										href={`/content/${sVal.videoid}/${sVal.group}`}
										className="row m-auto no-gutters align-items-center"
									>
										<div className="col-1">
											<FontAwesomeIcon icon="play-circle" color="#0096FF" />
										</div>
										<div className="col-8 pr-2">
											<a href={`/content/${sVal.videoid}/${sVal.group}`}>
												<h6 className="mb-0">{sVal.videoname}</h6>
											</a>
										</div>
										<div className="dashboard-sub-group-right-panel col-3">
											{sVal.progress_video_length === sVal.videolength ? (
												<ProgressBar
													bgcolor="#76B305"
													hideClass={false}
													displayInfo={true}
													completed={(sVal.completedVideoPercentage = 100)}
												/>
											) : (
												(sVal.content_type === 'Video' || sVal.content_type === 'video') && (
													<ProgressBar
														bgcolor="#0096FF"
														hideClass={false}
														displayInfo={true}
														completed={
															sVal.completedVideoPercentage > 0 ? sVal.completedVideoPercentage : 0
														}
													/>
												)
											)}
										</div>
									</a>
								</div>
							))}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
export default ContentDetail;
