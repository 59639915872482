// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
//import { faMoneyBill } from '@fortawesome/pro-solid-svg-icons';
import { faCode, faHighlighter ,faHome ,faArrowAltCircleRight,faArrowAltCircleLeft, faCheckCircle,
faUser,faEnvelope,faLock,faBriefcase,faUserTag,faChevronLeft,faSquare,faKey,faPowerOff,faArrowDown,faLongArrowAltDown,
faChevronDown,faChevronUp,faStopwatch,faTimes,faGlobe,faDownload,faFileDownload,faLanguage,faSignLanguage, faPlayCircle, faTag
} from '@fortawesome/free-solid-svg-icons';

library.add(
  //faMoneyBill,
  faPlayCircle,
  faCode,
  faHighlighter,
  faHome,
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
  faCheckCircle,
  faUser,
  faEnvelope,
  faLock,
  faBriefcase,
  faUserTag,
  faChevronLeft,
  faSquare,
  faKey,
  faPowerOff,
  faArrowDown,
  faLongArrowAltDown,
  faChevronDown,
  faChevronUp,
  faStopwatch,
  faTimes,
  faGlobe,
  faDownload,
  faFileDownload,
  faLanguage,
  faSignLanguage,
  faTag
  // more icons go here
);