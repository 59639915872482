import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../Assets/fontawesome';
import tabnulogo from "../../Assets/Images/ui/tabnuTempLogo.png"
import Axios from 'axios'
//import * as serviceWorker from '../../serviceWorker';
import './videoDetail.css'
import ProgressBar from '../../Directives/ProgressBar/progressBar'
import { Document, Page, pdfjs } from 'react-pdf';

// const apiUrlPrefix = serviceWorker.applicationConfig().apiUrlPrefix;
// const documentPath = serviceWorker.applicationConfig().documentUrl;
const apiUrlPrefix =  process.env.REACT_APP_APIURLPREFIX;//serviceWorker.applicationConfig().apiUrlPrefix;
const documentPath =  process.env.REACT_APP_DOCUMENTURL;//serviceWorker.applicationConfig().documentUrl;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
let analyticId = 0;

export default class VideoDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      video: {},
      next: {},
      previous: {},
      videoId: parseInt(this.props.match.params.videoId),
      groupId: parseInt(this.props.match.params.groupId),
      attachmentname: '',
      numPages: 0,
      pageNumber: 1,
      otherVideoList: []
    };
  }

  componentDidMount = async () => {
    try {
      this.setState({ isLoading: true });
      const params = {
        groupId: this.state.groupId,
        userId: this.props.userId,
        languageId: this.props.userLanguageId
      };
      var res = await Axios.post(apiUrlPrefix + "/getContentsClient", params, this.props.apiHeaderRequest)
        .catch(err => {
          console.log(err);
          this.setState({ isLoading: false });
        });
      if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
        const list = res.data.body.sort(x => x.sequence);
        if (list != null) {
          this.setState({ otherVideoList: list.filter(x => x.videoid !== this.state.videoid) });
        }
        const index = list.findIndex(x => x.videoid === this.state.videoId);
        const current = list[index];
        const previous = list[index === 0 ? list.length - 1 : index - 1];
        const next = list[index === list.length - 1 ? 0 : index + 1];

        if (current != null) {
          if (index > 0) {
            this.setState({ previous: { id: previous.videoid, image: previous.thumbnailkey, groupId: previous.group, name: previous.videoname, isShow: (previous.group === current.group) } });
          }
          if (index !== list.length - 1) {
            this.setState({ next: { id: next.videoid, image: next.thumbnailkey, groupId: next.group, name: next.videoname, isShow: (next.group === current.group) } });
          }

          this.setState({ video: current });
          if (current.analyticsid > 0) {
            analyticId = current.analyticsid;
          }

          if (current.content_type === 'video' || current.content_type === 'Video') {

            this.interval = setInterval(async () => {
              console.log("interval part");
              var element = document.getElementById("videoPlayer");
              {
                if (element && ((current.videolength >= Math.round(element.currentTime) && !element.paused) ||
                current.videolength === Math.round(element.currentTime) && element.paused)) {
                  const params = {
                    analyticsId: analyticId,
                    version: 0,
                    userId: parseInt(this.props.userId),
                    videoId: this.state.videoId,
                    languageId: parseInt(this.props.userLanguageId),
                    progressVideoLength: Math.round(element.currentTime)
                  };
                  await Axios.post(apiUrlPrefix + "/updateContentClient", params, this.props.apiHeaderRequest)
                    .then((res) => {
                      if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
                        analyticId = res.data.body[0].id;
                      }
                    })
                    .catch(err => {
                      console.log(err);
                    });
                }

              }
            }, 10000)
          }

        }
      }
      this.setState({ isLoading: false });
    } catch (error) {
      if (error !== 'No current user') {
        console.log(error);
      }
    }
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleVideoMounted = element => {
    if (element !== null) {
      element.currentTime = parseFloat(this.state.video.progress_video_length > 0 ? this.state.video.progress_video_length : 0);
    }

  };


  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages: numPages });
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 video-header pl-0 pr-0">
            <a href="/dashboard"> <FontAwesomeIcon icon="home" size="1x" /></a>
            <h4 style={{ display: 'initial', marginLeft: '15px' }}> {this.state.video.videoname}</h4>
          </div>
        </div>
        <div className="row" >
          {
            this.state.video.attachmentkey &&
            <React.Fragment>
              <div className="col-md-6 pl-0 ">
                <a href={documentPath + this.state.video.attachmentkey + '?v=' + Math.random()}>
                  <div className="download-content ">
                    <div className="col-sm-2 download-icon-postion">
                      <FontAwesomeIcon icon="download" size="2x" color="white" />
                    </div>
                    <div className="col-sm-8 download-text-space" >
                      <span className="download-text-heading">Download document</span>
                      <p className="download-text-description"> {this.state.video.attachmentkey.length > 0 && this.state.video.attachmentkey.split("/").pop()}</p>
                    </div>
                  </div>
                </a>
              </div>

              <div className="col-md-5 download-text-space pr-0">
                <p>Download the document to help you get started on this module</p>
              </div>
            </React.Fragment>
          }
        </div>
        <div className="row video-page-extra-margin">
          <div className="col-md-12 pl-0 pr-0">

            {
              this.state.video && (this.state.video.content_type === 'Video' || this.state.video.content_type === 'video') &&

              <video src={documentPath + this.state.video.videokey + '?v=' + Math.random()} controls controlsList="nodownload"
                ref={this.handleVideoMounted} id="videoPlayer"
                style={{ width: '100%', hieght: '300px', background: 'black' }} ></video>
            }

            {
              this.state.video && (this.state.video.content_type === 'Document' ||
                this.state.video.content_type === 'document') &&

              <React.Fragment>
                <Document
                  file={{
                    url: documentPath + this.state.video.videokey + '?v=' + Math.random(),
                    httpHeaders: {
                      'Access-Control-Allow-Origin': '*'
                    }
                  }}
                  onLoadSuccess={this.onDocumentLoadSuccess}
                >
                  <Page pageNumber={this.state.pageNumber} size="A4" />
                  <div className="page-controls">
                    <button className="btn" disabled={this.state.pageNumber === 1} type="button" onClick={() => this.setState({ pageNumber: this.state.pageNumber - 1 })}>‹</button>
                    <span>{this.state.pageNumber} of {this.state.numPages}</span>
                    <button className="btn" disabled={this.state.pageNumber === this.state.numPages} type="button" onClick={() => this.setState({ pageNumber: this.state.pageNumber + 1 })}>›</button>
                  </div>
                </Document>
              </React.Fragment>
            }

          </div>
        </div>
        <div className="row video-page-extra-margin">
          <div className="col-md-8 m-auto">
            <p>
              {this.state.video.description}
            </p>
          </div>
          <div className="col-md-4  pr-0">
            {
              this.state.video.videolength === this.state.video.progress_video_length &&
              <span className="download-link"><FontAwesomeIcon icon="check-circle" color="White" /> Completed </span>
            }

            <p>Last viewed on:
              <br>
              </br>{this.state.video.updatedAt} 00:00</p>
              <hr/>
              {
                                       this.state.otherVideoList && this.state.otherVideoList.map((sVal,sInd)=>(
                                         
                                              <div key={sInd} className='col-md-11 mx-auto my-3 pt-2 video-content-container'  style={{ cursor: "pointer"}}>
                                                  <a href={`/content/${sVal.videoid}/${sVal.group}`}>
                                                  
                                                  <div>
                                                    <a href={`/content/${sVal.videoid}/${sVal.group}`}>
                                                        <h5>{sVal.videoname}</h5>
                                                    </a>
                                                      <div className='dashboard-sub-group-right-panel pb-3'>
                                                      <span> <FontAwesomeIcon icon="stopwatch"  /> Approx time: {sVal.displayTime} </span>
                                                          { sVal.progress_video_length===sVal.videolength? <ProgressBar  bgcolor="#76B305" hideClass={false} displayInfo={true} completed={sVal.CompletedPercentage=100}  /> :
                                                          (
                                                            (sVal.content_type==='Video' || sVal.content_type==='video' ) &&  <ProgressBar  bgcolor="#0096FF" hideClass={false} displayInfo={true} completed={sVal.CompletedPercentage>0?sVal.CompletedPercentage:0}  />
                                                            )}
                                                      {
                                                        sVal.attachmentkey &&
                                                        <a href={documentPath+sVal.attachmentkey+'?v='+Math.random()}>
                                                            <span className="download-link"><FontAwesomeIcon icon="download" color="White"  /> Download</span>
                                                        </a>
                                                        
                                                      }
                                                      </div>
                                                  
                                                  </div>
                                                  </a>
                                              </div>
                                          
                                          ))
    
                                  }
          </div>
        </div>
        {
          this.state.previous && this.state.previous.isShow &&
          <a href={`/video/${this.state.previous.id}/${this.state.previous.groupId}`} className="video-navigation">
            {/* <div onClick={()=>{this.navigateVideoDetail(this.state.next.id,this.state.next.groupId)}}  className="video-navigation"> */}
            <div className="previous-video">
              <div className="flex flex-column items-center justify-center br2 ba bw3 b--white shadow-2 pa4 bg-gray7 white sub-video-container"
                style={{ backgroundSize: "cover", backgroundImage: "url('" + (documentPath + this.state.previous.image + '?v=' + Math.random()) + "') " }}
              >
                <FontAwesomeIcon icon="check-circle" size="2x" />
              </div>
              <div>{this.state.previous.name}</div>
            </div>
            {/* </div> */}
          </a>
        }
        {
          this.state.next && this.state.next.isShow &&
          <a href={`/video/${this.state.next.id}/${this.state.next.groupId}`} className="video-navigation">
            {/* <div onClick={()=>{this.navigateVideoDetail(this.state.next.id,this.state.next.groupId)}} className="video-navigation"> */}
            <div className="next-video">
              <div className="flex flex-column items-center justify-center br2 ba bw3 b--white shadow-2 pa4 bg-gray7 white sub-video-container"
                style={{ backgroundSize: "cover", backgroundImage: "url('" + (documentPath + this.state.next.image + '?v=' + Math.random()) + "') " }}>
                <FontAwesomeIcon icon="arrow-alt-circle-right" size="2x" />
              </div>
              <div>{this.state.next.name}</div>
            </div>
            {/* </div> */}
          </a>
        }

      </React.Fragment>
    )
  }
}
const FromUrl = () => (
  <div className='col-sm-12 text-center'>
    <h1 className='text-white bg-info rounded'>Fetch PDF by URL</h1>
    <div className='border rounded'>
      {/* <PDFViewer
              document={{
                  url: 'https://zsah-training-portal.s3-eu-west-1.amazonaws.com/attchment/10_best_practices_for_building_effective_dashboardswp.pdf',
              }}
              showThumbnail={{ scale: 3 }}
          /> */}
    </div>
  </div>
)
