import React, { Component } from 'react';
import { Button,  FormGroup, FormControl } from 'react-bootstrap';
import {Input} from 'reactstrap'
import tabnulogo from "../../Assets/Images/ui/tabnuTempLogo.png"
//import zsahawards from "../../Assets/Images/zsah4awards.png"
import zsahlogo from "../../Assets/Images/ui/zsahLogo.png"
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { LinkContainer } from 'react-router-bootstrap';
import { Checkbox } from '@material-ui/core';
import './registrationStyle.css';
import rightBanner from '../../Assets/Images/logos.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../Assets/fontawesome';
import Axios from 'axios';
import Modal from '../../Directives/ModalPopUp/modal';
//import * as serviceWorker from '../../serviceWorker';

//const apiUrlPrefix =serviceWorker.applicationConfig().apiUrlPrefix;

const apiUrlPrefix =  process.env.REACT_APP_APIURLPREFIX;//serviceWorker.applicationConfig().apiUrlPrefix;
//const userId=serviceWorker.applicationConfig().defaultUserId;

export default class Registration extends Component {
    
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
      fullName:'',
      email: '',
			password: '',
			confirmPassword: '',
      role:'',
      companyId:0,
      languageId:1,
      languageList:[],
      companyList:[],
      isSubscribe:false,
      isTermsAndConditions:false,
      validateId:0,
      validateMessage:'',
      isModal:false
		};
	}

	focusOutValidateForm=()=> {
    if(this.state.validateId>0){
      this.setState({validateId:0});
      this.setState({validateMessage:''});
    }
    if( this.state.fullName.length === 0){
      this.setState({validateId:1});
      this.setState({validateMessage:"Name is required"});
      return false;
    }
    else if( this.state.email.length === 0){
      this.setState({validateId:2});
      this.setState({validateMessage:"email is required"});
      return false;
    }
    else if( !this.ValidateEmail(this.state.email)){
      this.setState({validateId:3});
      this.setState({validateMessage:"email pattern is not macthing"});
      return false;
    }
    else if( this.state.password.length === 0){
      this.setState({validateId:4});
      this.setState({validateMessage:"Password is required"});
      return false;
    }
    else if(!this.ValidatePassword (this.state.password)){
      this.setState({validateId:5});
      this.setState({validateMessage:"Password should 1 uppercase,1 lowercase, 1 Number and 1 special character."});
      return false;
    }
    else if( this.state.confirmPassword.length === 0){
      this.setState({validateId:6});
      this.setState({validateMessage:"Confirm password is required"});
      return false;
    }
    else if( this.state.confirmPassword!==this.state.password){
      this.setState({validateId:7});
      this.setState({validateMessage:"confirm password not matched"});
      return false;
    }
    else if( this.state.companyId === 0){
      this.setState({validateId:8});
      this.setState({validateMessage:"Company is required"});
      return false;
    }
    else if( this.state.languageId===0){
      this.setState({validateId:9});
      this.setState({validateMessage:"Please accept terms and conditions"});
      return false;
    }
    else if( !this.state.isTermsAndConditions){
      this.setState({validateId:10});
      this.setState({validateMessage:"Please accept terms and conditions"});
      return false;
    }
    else{
      return true;
    }
  }
  
  ValidateEmail(mail) 
  {
  if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail))
    {
      return true;
    }
    return false;
  }

  ValidatePassword(password) 
  {
  if (/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(password))
    {
      return true;
    }
    return false;
  }

  validateForm(){
		return (
      this.state.fullName.length > 0 &&
      this.state.companyId > 0 &&
			this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      //this.state.languageId > 0 &&
      //this.state.isAccept==true &&
			this.state.password === this.state.confirmPassword
		);
  }
  async componentDidMount() {
    try {
        await this.loadLanguage();
        await this.loadCompany();
    } catch(error) {
      if (error !== 'No current user') {
      console.log(error);
      }
    }
  }

  loadLanguage=()=>{
    Axios.post(apiUrlPrefix + "/getLanguages")
    .then((res) => {
        if(res!=null && res.data!=null &&  res.data.statusCode===200 && res.data.body.length>0 ){
          let list = res.data.body;
          this.setState({languageList:list});
        }
    })
    .catch(err => {  
        console.log(err) 
    });
  }

   loadCompany=()=>{
    Axios.post(apiUrlPrefix + "/getOrganisationsClient")
    .then((res) => {
        if(res!=null && res.data!=null && res.data.statusCode===200 && res.data.body.length>0 ){
          let list = res.data.body;
          this.setState({companyList:list});
        }
    })
    .catch(err => {  
        console.log(err) 
    });
  }

	validateConfirmationForm() {
		return this.state.confirmationCode.length > 0;
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
  };
  
  showMessage=()=>{
    return (<alert visible={true} title='www' body='hhhggh'  />);
  }

	handleSubmit = async event => {
    event.preventDefault();

    if (this.state.isLoading) { return; }
    this.setState({email:this.state.email.toLowerCase()});
    const validationStatus = this.focusOutValidateForm();
    if(!validationStatus){
      return false;
    }
		this.setState({ isLoading: true });

		try {
      const params = {
       // username:this.state.email,
        password:this.state.password,
        name:this.state.fullName,
        email:this.state.email.toLowerCase(),
        designation:this.state.role,
        companyId:parseInt(this.state.companyId),
        defaultLanguage:parseInt(this.state.languageId),
        isSubscribe: this.state.isSubscribe,
      }
      //console.log('Request: '+JSON.stringify(params));
      Axios.post(apiUrlPrefix + "/createUser",params)
      .then((res) => {
          if(res!=null && res.data!=null && res.data.statusCode===200 && res.data.body!=null ){
            this.setState({ isLoading: false });//need to discuss with karthik
            if(res.data.body.id>0){
              
              this.setState({isModal: !this.state.isModal});
            }
            else{
              this.setState({validateId:11});
              this.setState({validateMessage:res.data.body});
            }
          }
      })
      .catch(err => {  
        this.setState({ isLoading: false });
        console.log(err) 
      });
		} catch (e) {
			console.log(e.message);
		}
  };

  toggleModal = () => {
    this.setState({isModal: !this.state.isModal});
    this.props.history.push('/login');
  }

	render() {
		return (
            
<div className="mai-wrapper mai-login ">
<div className="main-content container-fluid">
  <div className="splash-container row">
    <div className="col-md-6 login-form-main">
      <div className="row">
        <div className=" col-md-12 form-message ">
          <div className="registration-content-container registration-font">
            <div className="logo-top-space text center logo-center " >
              <img src={tabnulogo} alt="zsah-white" className="img-fluid tabnu-logo" />
            </div>
            <div className="logo-text text center logo-center logo-tag-line" >
            <p className="text-center">
               
             <span>Register a new account</span></p>
          </div>
          <form onSubmit={this.handleSubmit} className="">

<div className="row">
<div className="col-md-6">
<FormGroup controlId="fullName" >
    
  
    <div className="registration-input-group">
      
      <span  className="input-icon-style">
    <FontAwesomeIcon icon="user" />
    </span>
          <FormControl
           autoFocus
            type="text"
            value={this.state.fullName}
            placeholder="full Name"
            onChange={this.handleChange}
            className="registration-input-field"
            onKeyUp={this.focusOutValidateForm}
          />
         
        </div>
    {this.state.validateId===1 && <span className="registration-validation">{this.state.validateMessage}</span>}
        
    </FormGroup>
</div>
<div className="col-md-6">
<FormGroup controlId="email" >
          <div     className="registration-input-group">
            <span  className="input-icon-style">
          <FontAwesomeIcon icon="envelope" />
          </span>
                <FormControl
                  type="email"
                  placeholder="Email"
                  value={this.state.email.toLowerCase()} onChange={this.handleChange}
                  className="registration-input-field"
                  onKeyUp={this.focusOutValidateForm}
                />
              </div>
              {this.state.validateId===2 && <span className="registration-validation">{this.state.validateMessage}</span>}
              {this.state.validateId===3 && <span className="registration-validation">{this.state.validateMessage}</span>}
					</FormGroup>
</div>

<div className="col-md-6">
<FormGroup controlId="password">
          <div     className="registration-input-group">
            <span  className="input-icon-style">
          <FontAwesomeIcon icon="lock"  />
          </span>
                <FormControl
                  type="password"
                  placeholder="Password"
                  value={this.state.password} onChange={this.handleChange}
                  className="registration-input-field"
                  onKeyUp={this.focusOutValidateForm}
                />
              </div>
              {this.state.validateId===5 && <span className="registration-validation">{this.state.validateMessage}</span>}
              {this.state.validateId===4 && <span className="registration-validation">{this.state.validateMessage}</span>}
					</FormGroup>
</div>
<div className="col-md-6">
<FormGroup controlId="confirmPassword" >
          <div     className="registration-input-group">
            <span  className="input-icon-style">
              <FontAwesomeIcon icon="lock" />
            </span>
                <FormControl
                  type="password"
                  placeholder="confirm Password"
                  value={this.state.confirmPassword} onChange={this.handleChange}
                  className="registration-input-field"
                  onKeyUp={this.focusOutValidateForm}
                />
              </div>
              {this.state.validateId===6 && <span className="registration-validation">{this.state.validateMessage}</span>}
              {this.state.validateId===7 && <span className="registration-validation">{this.state.validateMessage}</span>}
					</FormGroup>
</div>

<div className="col-md-6">
<FormGroup controlId="companyId" >
          <div     className="registration-input-group">
            <span  className="input-icon-style">
          <FontAwesomeIcon icon="briefcase" />
          </span>
                {/* <FormControl
                  type="text"
                  placeholder="Company"
                  value={this.state.company} onChange={this.handleChange}
                  className="registration-input-field"
                  onKeyUp={this.focusOutValidateForm}
                /> */}
                <Input
            type="select"
            id="companyId"
            placeholder="Company"
            value={this.state.companyId} 
            className="registration-input-field registration-select-input"
            onChange={e => this.setState({ companyId: e.target.value })}
            onKeyUp={this.focusOutValidateForm}
            >
              <option value="0">Select Company</option>
                {
                this.state.companyList && this.state.companyList.map(obj=>(
                  <option value={obj.id} key={obj.id}>{obj.name}</option>
                ))}
          </Input>
              </div>
              {this.state.validateId===8 && <span className="registration-validation">{this.state.validateMessage}</span>}
					</FormGroup>
</div>
<div className="col-md-6">
<FormGroup controlId="role">
          <div     className="registration-input-group">
            <span  className="input-icon-style">
              <FontAwesomeIcon icon="briefcase" />
            </span>
                <FormControl
                  type="text"
                  placeholder="Role(optional)"
                  value={this.state.role} onChange={this.handleChange}
                  className="registration-input-field"
                />
              </div>
					</FormGroup>
</div>

<div className="col-md-12">
<FormGroup controlId="languageId" >
          <div     className="registration-input-group">
            <span  className="input-icon-style">
          <FontAwesomeIcon icon="globe" />
          </span>
          <Input
            type="select"
            id="languageId"
            placeholder="Select language"
            className="registration-input-field registration-select-input"
            value={this.state.languageId}
            onChange={e => this.setState({ languageId: e.target.value })}
            onKeyUp={this.focusOutValidateForm}
            >
                {
                this.state.languageList && this.state.languageList.map(obj=>(
                  <option value={obj.id} key={obj.id}>{obj.name}</option>
                ))}
          </Input>
              </div>
              {this.state.validateId===9 && <span className="registration-validation">{this.state.validateMessage}</span>}
					</FormGroup> 
</div>
<div className="col-md-6">

</div>

<div className="col-md-12 registration-remove-space ml-3">
<FormGroup controlId="isSubscribe" >
  
    <FormControlLabel
      control={<Checkbox color="primary" />}
      onChange={e=>(this.setState({isSubscribe:!this.state.isSubscribe}))}
      checked={this.state.isSubscribe}
      className="registration-input-field registration-checkbox"
    />
  <span className="check-font">Click here to receive updates when new content is added to the portal</span>
        
					</FormGroup>
</div>
<div className="col-md-12 registration-remove-space mb-5 ml-3">
<FormGroup controlId="isTermsAndConditions" >
          <FormControlLabel
              control={<Checkbox color="primary" />}
              onChange={e=>(this.setState({isTermsAndConditions:!this.state.isTermsAndConditions}))}
              checked={this.state.isTermsAndConditions}
              className="registration-input-field registration-checkbox"
              onKeyUp={this.focusOutValidateForm}
            />
            <span className="check-font">Accept our <a href="/terms" className="registration-hyper-link">terms & conditions</a></span>
					</FormGroup>
          {this.state.validateId===10 && <span style={{ 'marginLeft': '10px','paddingTop':'10px'}}className="registration-validation">{this.state.validateMessage}</span>}
</div>


<div className="col-md-6">
<div className="form-group login-submit ">

<LinkContainer to="/login" >
    <span className="btn btn-lg btn-dark registration-btn apply-hand login-btns">Go Back</span>
</LinkContainer>

</div> 
</div>
<div className="col-md-6">
<div className="form-group login-submit ">
  <Button variant="primary" block className="btn btn-lg btn-primary registration-btn apply-hand login-btns" type="submit" >
    {!this.state.isLoading ? 'Register' : 'Please wait...'}
  </Button>
</div> 
            {this.state.validateId===11 && <span className="registration-validation">{this.state.validateMessage}</span>}
</div>
            </div>
           
          </form>
          <div className="mt-5 out-links footer-logo-center"><span>Powered by</span><br/>
        {/* <img src={tableaulogo} alt="zsah-white" className="login-footer-image" />
        <span className="padding-space"></span> */}
        <img src={zsahlogo} alt="zsah-white" className="login-footer-image" />
      </div>
          <Modal show={this.state.isModal} onClose={()=>{this.toggleModal()}}>
            <h3>Thank you for applying to register with us</h3>
            We'll review your details and pass on a confirmation link via email
          </Modal>

        </div>
        
    </div>
  </div>
  
</div>
<div className="col-md-6 py-4 m-auto text-center">
  <img src={rightBanner} alt="Training Portal" className="logos-img pt-3" />
  <div id='myAlertbox'></div>
</div>
</div>
</div>
</div>
		);
	}
}