import tabnulogo from '../../Assets/Images/ui/tabnuTempLogo.png';
import zsahawards from '../../Assets/Images/zsah4awards.png';
import zsahlogo from '../../Assets/Images/ui/zsahLogo.png';
import rightBanner from '../../Assets/Images/logos.jpg';
import React, { Component } from 'react';
import { Button, FormGroup, FormControl } from 'react-bootstrap';
import '../Login/loginStyle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../Assets/fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import { Auth } from 'aws-amplify';

export default class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			isCodeSent: false,
			email: '',
			code: '',
			password: '',
			confirmPassword: ''
		};
	}

	validateForm() {
		return (
			this.state.confirmPassword.length > 0 &&
			this.state.password.length > 0 &&
			this.state.confirmPassword === this.state.password
		);
	}

	validateEmail() {
		return this.state.email.length > 0;
	}

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	SendCodeOnSubmit = async (event) => {
		event.preventDefault();
		this.setState({email:this.state.email.toLowerCase()});
		this.setState({ isCodeSent: true });
		await Auth.forgotPassword(this.state.email)
			.then((data) => console.log(data), alert('Please check your email for code'))
			.catch((err) => console.log(err));
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		if (this.state.isLoading) {
			return;
		}
		this.setState({email:this.state.email.toLowerCase()});
		this.setState({ isLoading: true });
		try {
			await Auth.forgotPasswordSubmit(this.state.email, this.state.code, this.state.password)
				.then((data) => console.log(data), alert('Password updated successfully'), this.props.history.push('/'))
				.catch((err) => console.log(err));
		} catch (e) {
			alert(e.message);
			this.setState({ isLoading: false });
		}
	};

	render() {
		return (
			<div className="mai-wrapper mai-login">
				<div className="main-content container-fluid">
					<div className="splash-container row">
						<div className="col-md-6 login-form-main">
							<div className="row">
								<div className=" col-md-9 m-auto form-message">
									<div className="login-content-container">
										<div className="logo-top-space text center logo-center ">
											<img src={tabnulogo} alt="zsah-white" className="img-fluid tabnu-logo" />
										</div>
										<div className="logo-text text center logo-center ">
											<p className="text-center">Tableau Nurturing Portal</p>
										</div>
										{this.state.isCodeSent ? (
											<form onSubmit={this.handleSubmit} className="">
												<FormGroup controlId="code">
													<div className="login-input-group">
														<span className="input-icon-style">
															<FontAwesomeIcon icon="user" />
														</span>
														<FormControl
															autoFocus
															type="text"
															placeholder="Enter code"
															value={this.state.code}
															onChange={this.handleChange}
															className="login-input-field"
														/>
													</div>
												</FormGroup>

												<FormGroup controlId="password">
													<div className="login-input-group">
														<span className="input-icon-style">
															<FontAwesomeIcon icon="lock" />
														</span>
														<FormControl
															autoFocus
															placeholder="Password"
															type="password"
															value={this.state.password}
															onChange={this.handleChange}
															className="login-input-field"
														/>
													</div>
												</FormGroup>
												<FormGroup controlId="confirmPassword">
													<div className="login-input-group">
														<span className="input-icon-style">
															<FontAwesomeIcon icon="lock" />
														</span>
														<FormControl
															autoFocus
															type="password"
															placeholder="Confirm Password"
															value={this.state.confirmPassword}
															onChange={this.handleChange}
															className="login-input-field"
														/>
													</div>
												</FormGroup>

												<div className="form-group login-submit login-submit1">
													<Button
														block
														className="btn btn-lg btn-primary apply-hand"
														disabled={!this.validateForm()}
														type="submit"
													>
														{!this.state.isLoading ? 'Change Password' : 'Loading...'}
													</Button>
												</div>
												<div className="form-group login-submit row">
													<LinkContainer to="/" className="col-md-5 apply-hand">
														<span className="btn btn-lg btn-light login-btn-full-width">
															Go Back
														</span>
													</LinkContainer>
													<span className="col-md-2" />
													<div
														onClick={this.SendCodeOnSubmit}
														className="col-md-5 btn btn-lg btn-light login-btn-full-width  apply-hand"
													>
														Resend Code
													</div>
												</div>
											</form>
										) : (
											<form onSubmit={this.SendCodeOnSubmit}>
												<h3 className="text-center">Looks like you forgot your password.</h3>
												<p className="text-center">
													Enter registered email address to recieve reset code.
												</p>
												<FormGroup controlId="email">
													<div className="login-input-group">
														<span className="input-icon-style">
															<FontAwesomeIcon icon="user" />
														</span>
														<FormControl
															autoFocus
															type="email"
															placeholder="Enter email"
															value={this.state.email}
															onChange={this.handleChange}
															className="login-input-field"
														/>
													</div>
												</FormGroup>
												<div className="form-group login-submit login-submit1">
													<Button
														block
														className="btn btn-lg btn-primary apply-hand"
														disabled={!this.validateEmail()}
														type="submit"
													>
														{!this.state.isLoading ? 'Send Code' : 'Loading...'}
													</Button>
												</div>
											</form>
										)}
										<div className="mt-5 out-links login-footer-logo-center ">
											<span>Powered by</span>
											<br />

											<img src={zsahlogo} alt="zsah-white" className="login-footer-image" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 text-center">
							<img src={rightBanner} alt="Training Portal" className="logos-img pt-3" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
