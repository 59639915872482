import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AppliedRoute from './Directives/Route/appliedRoute';

//import NotFound from './Components/NotFound/notFound';
import Login from './Components/Login/login';
import Registration from './Components/Registration/registration';
import Dashboard from './Components/Dashboard/dashboard';
import VideoDetail from './Components/VideoDetail/videoDetail';
import ContentDetail from './Components/VideoDetail/contentDetail';
import ResetPassword from'./Components/ResetPassword/resetPassword';
import ResetPasswordInternal from'./Components/ResetPasswordInternal/resetPasswordInternal';
import Terms from './Components/TermsAndConditions/terms'

export default ({ childProps }) => (
	<Switch>
		{childProps.isAuthenticated?
		(
			<React.Fragment>
			<AppliedRoute path="/" exact component={Dashboard} props={childProps} />
			<AppliedRoute path="/dashboard" exact component={Dashboard} props={childProps} />
			<AppliedRoute path="/dashboard/:groupId" exact component={Dashboard} props={childProps} />
			<AppliedRoute path="/resetpassword" exact component={ResetPasswordInternal} props={childProps} />
			<AppliedRoute path="/video/:videoId/:groupId" exact component={VideoDetail} props={childProps} />
			<AppliedRoute path="/content/:videoId/:groupId" exact component={ContentDetail} props={childProps} />
			</React.Fragment>
		):
		(
			<React.Fragment>
			<AppliedRoute path="/" exact component={Login} props={childProps} />
			<AppliedRoute path="/login" exact component={Login} props={childProps} />
			<AppliedRoute path="/resetpassword" exact component={ResetPassword} props={childProps} />
			<AppliedRoute path="/registration" exact component={Registration} props={childProps} />
			<AppliedRoute path="/terms" exact component={Terms} props={childProps} />
			</React.Fragment>
			)
		}
	</Switch>
);
