import React from "react"
import ReactDOM from "react-dom"
import * as serviceWorker from './serviceWorker';
//import Config from './config'
import Amplify from 'aws-amplify';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';

console.log('envName:'+process.env.NODE_ENV);
console.log('REACT_APP_TEST_LINK: '+process.env.REACT_APP_TEST_LINK);

Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		region: process.env.REACT_APP_COGNITOREGION,// Config.cognito.REGION,
		userPoolId: process.env.REACT_APP_COGNITOUSERPOOL,//Config.cognito.USER_POOL_ID,
		identityPoolId: 'YOUR_IDENTITY_POOL_ID',//Config.cognito.IDENTITY_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_COGNITOCLIENTID,//Config.cognito.APP_CLIENT_ID
	},
	API: {
		endpoints: [
			{
				name: 'testApiCall',
				endpoint: 'YOUR_API_GATEWAY_URL',//Config.apiGateway.URL,
				region: process.env.REACT_APP_COGNITOREGION,//Config.apiGateway.REGION
			}
		]
	}
});


ReactDOM.render(
	<Router >
		<App />
	</Router>,
	document.getElementById('root')
);
serviceWorker.register();
//ReactDOM.render(<BrowserRouter><Login/></BrowserRouter>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
