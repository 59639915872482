import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ProgressBar = (props) => {
  const { bgcolor, completed, displayInfo, hideClass } = props;

  const containerStyles = {
    height: 15,
    width: '100%',
    backgroundColor: "#dce0e9",
    borderRadius: 50,
    //   margin: 50
  }

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right'
  }

  const labelStyles = {
    padding: 5,
    fontWeight: 'bold'
  }
  const leftSection = {
    // position: 'absolute', 
    left: '0px',
    float: 'left',
    // padding: '8px',
  }
  const rightSection = {
    position: 'absolute',
    right: '15px',
    float: 'right',
    // padding: '8px'
  }
  const extraSpace = {
    paddingTop: '15px'
  }

  return (
    <div className={hideClass ? '' : 'row px-3 justify-content-end'} style={{ left: '30px' }}>

      <div style={containerStyles}>
        <div style={fillerStyles}>
          <React.Fragment>

            {completed == 0 ? <span style={labelStyles} className="text-secondary">{`${completed}%`}</span>
              : completed == 100 ? <span className="complete-video"><FontAwesomeIcon icon="check-circle" /></span>
                : <span style={labelStyles} className="text-white">{displayInfo ? `${completed}%` : ''}</span>
            }
          </React.Fragment>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;